import agenda from './agendamentos/agenda'
import agendamento from './agendamentos/agendamento'
import anexosDoAtendimento from './prontuario/anexos-do-atendimento'
import antibioticos from './antibioticos'
import arquivos from './arquivos'
import arquivosAdicionaisDoAtendimento from './prontuario/arquivos-adicionais-do-atendimento'
import arquivosDaInternacao from './arquivos-da-internacao'
import arquivosDeVideoConsulta from './prontuario/video-consulta'
import associacoes from './associacoes'
import atendimentos from './prontuario/atendimentos'
import avaliacoesDeSobrecargaDoCuidadorDoPaciente
  from './prontuario/gestao-de-condicoes-clinicas/questionarios/avaliacao-de-sobrecarga-do-cuidador'
import avaliacoesDaSolidaoDoPaciente from './prontuario/gestao-de-condicoes-clinicas/questionarios/avaliacao-da-solidao'
import avaliacoesFisicasEFuncionaisDoPaciente
  from './prontuario/gestao-de-condicoes-clinicas/questionarios/avaliacao-fisica-e-funcional'
import bloqueiosDaAgenda from './usuarios/agenda/bloqueios'
import camposExternosDoPaciente from './pacientes/campos-externos'
import casps16DoPaciente from './prontuario/gestao-de-condicoes-clinicas/questionarios/casp-16'
import categoriasDaTarefa from './tarefas/categoria'
import cabecalhoDoProntuario from './prontuario/cabecalho/cabecalho'
import celulas from './celulas'
import cep from './cep'
import cidadesParaOPedidoDeContato from './pedidos-de-contato/cidades'
import cids10 from './cids10'
import comentariosDaTarefa from './tarefas/comentarios'
import configuracoesDoUsuario from './usuarios/configuracoes'
import confirmarAgendamento from './agendamentos/confirmar-agendamento'
import consultasExternasDoPaciente from './prontuario/gestao-de-condicoes-clinicas/consultas-externas'
import conveniosDosPacientes from './pacientes/convenios'
import datasDaTarefa from './tarefas/data'
import desbloquearLoginDoUsuario from './usuarios/desbloquear-login-do-usuario'
import descricaoDaTarefa from './tarefas/descricao'
import diagnosticos from './diagnosticos'
import diagnosticosDoProntuario from './prontuario/gestao-de-condicoes-clinicas/diagnosticos'
import duracoesDasDores from './duracoes-das-dores'
import encaminhamentos from './encaminhamentos'
import enfermeiros from './enfermeiros'
import empresa from './empresa'
import escalasAmbientaisDeRiscoDeQuedaDoPaciente
  from './prontuario/gestao-de-condicoes-clinicas/questionarios/escala-ambiental-de-risco-de-quedas'
import especialidadesMedicasInternas from './especialidades-medicas-internas';
import estabelecimentosDeSaude from './estabelecimentos-de-saude'
import estadosCivis from './estados-civis'
import estadosDaMemoriaAtual from './estados-da-memoria-atual'
import estadosDaMemoriaComparadoHaUmAnoAtras from './estados-da-memoria-comparado-ha-um-ano-atras'
import examesDoAtendimento from './exames-do-atendimento'
import execucoesDoTesteDeFisioterapia from './execucoes-do-teste-de-fisioterapia'
import fabricantes from './fabricantes'
import feriados from './feriados'
import feriadosTrabalhados from './usuarios/agenda/feriados-trabalhados'
import frequenciasNaEscalaZarit from './prontuario/escala-zarit'
import fusosHorarios from './fusos-horarios'
import grausDeRelacoes from './graus-de-relacoes'
import gruposDeCuidado from './grupos-de-cuidado'
import horariosDaAgenda from './usuarios/agenda/horarios'
import idasAoProntoAtendimentoDoPaciente from './prontuario/gestao-de-condicoes-clinicas/idas-ao-pronto-atendimento'
import importacaoDePacientes from './importacoes/pacientes'
import indicadoresDoPaciente from './pacientes/indicadores'
import infeccoesDoTratoUrinario from './prontuario/gestao-de-condicoes-clinicas/infeccoes-do-trato-urinario'
import informarChegadaDoPaciente from './agendamentos/informar-chegada-do-paciente'
import instrucoesDaAgenda from './usuarios/agenda/instrucoes'
import intensidadesDasDores from './intensidades-das-dores'
import internacoesDoPaciente from './prontuario/gestao-de-condicoes-clinicas/internacoes'
import ivcfsDoPaciente from './prontuario/gestao-de-condicoes-clinicas/questionarios/ivcf'
import lacuna from './lacuna'
import linhaDoTempoDoProntuario from './prontuario/linha-do-tempo'
import listaDeOpcoesMultiplas from './tarefas/lista-de-opcoes-multiplas'
import locaisDaAplicacao from './locais-da-aplicacao'
import locaisDasDores from './locais-das-dores'
import locaisDeQueda from './locais-de-queda'
import medicos from './medicos'
import medicamentos from './medicamentos'
import medicamentosDoProntuario from './prontuario/gestao-de-condicoes-clinicas/medicamentos'
import memed from './prontuario/memed'
import microrganismos from './microrganismos'
import miniMentais from './prontuario/gestao-de-condicoes-clinicas/questionarios/mini-mental'
import modalidadesDosTiposDeAgendamento from './modalidades-dos-tipos-de-agendamento'
import momentosDaTarefa from './tarefas/momento'
import motivosDeBloqueioDaAgenda from './usuarios/agenda/motivos-de-bloqueio'
import motivosDeBloqueioDoLogin from './motivos-de-bloqueio-do-login'
import motivosDeCancelamentoDaTarefa from './tarefas/motivo-do-cancelamento'
import motivosDeCancelamentoDoConvenio from './pacientes/motivos-de-cancelamento-do-convenio'
import motivosDeCancelamentoDosAgendamentos from './agendamentos/motivos-de-cancelamento'
import motivosDeIdaAoProntoAtendimento from './motivos-de-ida-ao-pronto-atendimento'
import motivosDeInativacaoDoPaciente from './motivos-de-inativacao-do-paciente'
import motivosDeInternacao from './motivos-de-internacao'
import motivosDePrioridade from './motivos-de-prioridade'
import motivosDeSaidaDosProgramas from './pacientes/motivos-de-saida-dos-programas'
import motivosParaNaoTerSidoAtendido from './agendamentos/motivos-para-nao-ter-sido-atendido'
import niveisDeSaudeRelativa from './niveis-de-saude-relativa'
import novoAgendamento from './agendamentos/novo-agendamento'
import nps from './nps/index'
import numerosInteirosDaTarefa from './tarefas/numero-inteiro'
import operadoras from './operadoras'
import orientacoes from './orientacoes'
import orientacoesDePagina from './orientacoes-de-paginas'
import pacientes from './pacientes/pacientes'
import pacientesDaTarefa from './tarefas/paciente'
import pedidosDeContato from './pedidos-de-contato';
import perfis from './usuarios/perfis'
import pesquisaDeAgendamentos from './agendamentos/pesquisa-de-agendamentos'
import phqs9 from './prontuario/gestao-de-condicoes-clinicas/questionarios/phq9'
import planosDeCuidado from './planos-de-cuidado'
import planosDeCuidadoDoPaciente from './pacientes/planos-de-cuidado'
import planosDeSaudeParaOPedidoDeContato from './pedidos-de-contato/planos-de-saude'
import powerbi from './integracoes/power-bi'
import prazoDaTarefa from './tarefas/prazo'
import preCadastroDoPaciente from './pacientes/pre-cadastro'
import prescricao from './prescricao'
import previsoesDeRetorno from './previsoes-de-retorno'
import profissionalResponsavelDaTarefa from './tarefas/profissional-responsavel'
import profissionaisDeSaude from './profissionais-de-saude'
import profissionaisExternos from './profissionais-externos'
import profissoes from './profissoes'
import profissoesDoNps from './nps/profissoes'
import programas from './programas'
import programasDosPacientes from './pacientes/programas'
import proprietariosDeTelefones from './proprietarios-de-telefones'
import proximosAgendamentosMedicosDoPaciente from './agendamentos/proximos-agendamentos-medicos-do-paciente'
import psicologos from './psicologos'
import quedasDoPaciente from './prontuario/gestao-de-condicoes-clinicas/quedas'
import recorrenciasDoAgendamento from './agendamentos/recorrencias'
import relatorioDeHorasDosProfissionais from './relatorios/horas-dos-profissionais'
import relatorioDeIdasAoProntoAtendimento from './relatorios/idas-ao-pronto-atendimento'
import relatorioDeInternacoes from './relatorios/internacoes'
import relatorioDeReinternacoes from './relatorios/reinternacoes'
import relatorioDeAtendimentos from './relatorios/atendimentos'
import relatorioDePacientes from './relatorios/pacientes'
import relatorioDePacientesDoPrograma from './relatorios/pacientes-do-programa'
import registrosDocumentaisDoPsicologoDoPaciente
  from './prontuario/gestao-de-condicoes-clinicas/registros-documentais-do-psicologo'
import respostasDoCasp16 from './prontuario/questionario-casp-16'
import respostasDoNps from './nps/respostas'
import respostasDoPhq9 from './respostas-do-phq9'
import resumoDeInternacoes from './relatorios/resumo-de-internacoes'
import salaDeEspera from './agendamentos/sala-de-espera'
import setores from './setores'
import setorResponsavelDaTarefa from './tarefas/setor-responsavel'
import sexos from './sexos'
import sorologias from './sorologias'
import statusDaTarefa from './tarefas/status'
import statusDeIdasAoProntoAtendimento from './status-de-idas-ao-pronto-atendimento'
import statusDeInternacoes from './status-de-internacoes'
import statusDosAgendamentos from './agendamentos/status-dos-agendamentos'
import sugestoes from './sugestoes'
import tagsDosPacientes from './pacientes/tags'
import tamanhosDePaginas from './tamanhos-de-paginas'
import tarefa from './tarefas/index'
import teleconsulta from './teleconsulta/index'
import telefonesDeContatoDaTarefa from './tarefas/telefone-de-contato'
import textosDaTarefa from './tarefas/texto'
import textosLongosDaTarefa from './tarefas/texto-longo'
import tiposDeAgendamento from './tipos-de-agendamento'
import tiposDeAgendamentoDoAgendamento from './agendamentos/tipos-de-agendamento'
import tiposDeAgendamentoDoUsuario from './usuarios/agenda/tipos-de-agendamento'
import tiposDeAtendimentoDoNps from './nps/tipos-de-atendimento'
import tiposDeAtendimentoDoProntuario from './prontuario/tipos-de-atendimento'
import tiposDeAtendimentoDoUsuario from './usuarios/tipos-de-atendimento'
import tiposDeAtividadeFisicaDeLazer from './tipos-de-atividade-fisica-de-lazer'
import tiposDeConsultaExterna from './tipos-de-consulta-externa'
import tiposDeHorarios from './usuarios/agenda/tipos-de-horarios'
import tiposDeInternacao from './tipos-de-internacao'
import tiposDeRepeticaoDeSerieDaAgenda from './tipos-de-repeticao-de-serie-da-agenda'
import tiposDeSala from './tipos-de-sala'
import tiposDeTarefa from './tarefas/tipos'
import tiposDeUnidade from './tipos-de-unidade'
import tratamentos from './tratamentos'
import unidadesDeCalcioIonico from './unidades-de-calcio-ionico'
import unidadesDoUsuario from './usuarios/unidades'
import unidadesFederativas from './unidades-federativas'
import urgenciasDaTarefa from './tarefas/urgencia'
import usuario from './usuarios/usuario'
import usuariosBloqueados from './usuarios/usuarios-bloqueados'
import vacinas from './vacinas'
import vacinasDoPaciente from './prontuario/gestao-de-condicoes-clinicas/vacinas'
import condicoesRelacionadasAosEstadosHabituais from './triagem/condicoes-relacionadas-aos-estados-habituais'
import intensidadesDasDoresDaTriagem from './triagem/intensidades-das-dores'
import situacoesAssociadasAAlteracaoOuPerdaDaConsciencia from './triagem/situacoes-associadas-a-alteracao-ou-perda-da-consciencia'
import situacoesAssociadasAAlteracoesGastrointestinais from './triagem/situacoes-associadas-a-alteracoes-gastrointestinais'
import situacoesAssociadasADorNoPeito from './triagem/situacoes-associadas-a-dor-no-peito'
import situacoesAssociadasAQueda from './triagem/situacoes-associadas-a-queda'
import situacoesAssociadasASintomasUrinarios from './triagem/situacoes-associadas-a-sintomas-urinarios'
import situacoesAssociadasAoLocalDaDorMusculoesqueletica from './triagem/sintomas-associados-ao-local-da-dor-musculoesqueletica'
import respostasAPressaoNoLocalDaHemorragia from './triagem/respostas-a-pressao-no-local-da-hemorragia'
import respostasValidasSobreDiarreia from './triagem/respostas-validas-sobre-diarreia'
import respostasValidasSobreVomito from './triagem/respostas-validas-sobre-vomito'
import resultadosDasTriagens from './triagem/resultados-das-triagens'
import triagens from './triagem/triagens'


export default {
  ...agenda,
  ...agendamento,
  ...anexosDoAtendimento,
  ...antibioticos,
  ...arquivos,
  ...arquivosAdicionaisDoAtendimento,
  ...arquivosDaInternacao,
  ...arquivosDeVideoConsulta,
  ...associacoes,
  ...atendimentos,
  ...avaliacoesDeSobrecargaDoCuidadorDoPaciente,
  ...avaliacoesDaSolidaoDoPaciente,
  ...avaliacoesFisicasEFuncionaisDoPaciente,
  ...bloqueiosDaAgenda,
  ...camposExternosDoPaciente,
  ...casps16DoPaciente,
  ...categoriasDaTarefa,
  ...cabecalhoDoProntuario,
  ...celulas,
  ...cep,
  ...cidadesParaOPedidoDeContato,
  ...cids10,
  ...comentariosDaTarefa,
  ...configuracoesDoUsuario,
  ...confirmarAgendamento,
  ...consultasExternasDoPaciente,
  ...conveniosDosPacientes,
  ...datasDaTarefa,
  ...desbloquearLoginDoUsuario,
  ...descricaoDaTarefa,
  ...diagnosticos,
  ...diagnosticosDoProntuario,
  ...duracoesDasDores,
  ...encaminhamentos,
  ...enfermeiros,
  ...empresa,
  ...enfermeiros,
  ...escalasAmbientaisDeRiscoDeQuedaDoPaciente,
  ...especialidadesMedicasInternas,
  ...estabelecimentosDeSaude,
  ...estadosCivis,
  ...estadosDaMemoriaAtual,
  ...estadosDaMemoriaComparadoHaUmAnoAtras,
  ...examesDoAtendimento,
  ...execucoesDoTesteDeFisioterapia,
  ...fabricantes,
  ...feriados,
  ...feriadosTrabalhados,
  ...frequenciasNaEscalaZarit,
  ...fusosHorarios,
  ...grausDeRelacoes,
  ...gruposDeCuidado,
  ...horariosDaAgenda,
  ...idasAoProntoAtendimentoDoPaciente,
  ...importacaoDePacientes,
  ...indicadoresDoPaciente,
  ...infeccoesDoTratoUrinario,
  ...informarChegadaDoPaciente,
  ...instrucoesDaAgenda,
  ...intensidadesDasDores,
  ...internacoesDoPaciente,
  ...ivcfsDoPaciente,
  ...lacuna,
  ...linhaDoTempoDoProntuario,
  ...listaDeOpcoesMultiplas,
  ...locaisDaAplicacao,
  ...locaisDasDores,
  ...locaisDeQueda,
  ...medicos,
  ...medicamentos,
  ...medicamentosDoProntuario,
  ...memed,
  ...microrganismos,
  ...miniMentais,
  ...modalidadesDosTiposDeAgendamento,
  ...momentosDaTarefa,
  ...motivosDeBloqueioDaAgenda,
  ...motivosDeBloqueioDoLogin,
  ...motivosDeCancelamentoDaTarefa,
  ...motivosDeCancelamentoDosAgendamentos,
  ...motivosDeCancelamentoDoConvenio,
  ...motivosDePrioridade,
  ...motivosDeIdaAoProntoAtendimento,
  ...motivosDeInativacaoDoPaciente,
  ...motivosDeInternacao,
  ...motivosDeSaidaDosProgramas,
  ...motivosParaNaoTerSidoAtendido,
  ...niveisDeSaudeRelativa,
  ...novoAgendamento,
  ...nps,
  ...numerosInteirosDaTarefa,
  ...operadoras,
  ...orientacoes,
  ...orientacoesDePagina,
  ...pacientes,
  ...pacientesDaTarefa,
  ...pedidosDeContato,
  ...perfis,
  ...pesquisaDeAgendamentos,
  ...phqs9,
  ...planosDeCuidado,
  ...planosDeCuidadoDoPaciente,
  ...planosDeSaudeParaOPedidoDeContato,
  ...powerbi,
  ...prazoDaTarefa,
  ...preCadastroDoPaciente,
  ...prescricao,
  ...previsoesDeRetorno,
  ...profissionalResponsavelDaTarefa,
  ...profissionaisDeSaude,
  ...profissionaisExternos,
  ...profissoes,
  ...profissoesDoNps,
  ...programas,
  ...programasDosPacientes,
  ...proprietariosDeTelefones,
  ...proximosAgendamentosMedicosDoPaciente,
  ...psicologos,
  ...quedasDoPaciente,
  ...recorrenciasDoAgendamento,
  ...relatorioDeHorasDosProfissionais,
  ...relatorioDeIdasAoProntoAtendimento,
  ...relatorioDeInternacoes,
  ...relatorioDeReinternacoes,
  ...relatorioDeAtendimentos,
  ...relatorioDePacientes,
  ...relatorioDePacientesDoPrograma,
  ...registrosDocumentaisDoPsicologoDoPaciente,
  ...respostasDoCasp16,
  ...respostasDoNps,
  ...respostasDoPhq9,
  ...resumoDeInternacoes,
  ...salaDeEspera,
  ...setores,
  ...setorResponsavelDaTarefa,
  ...sexos,
  ...sorologias,
  ...statusDaTarefa,
  ...statusDeIdasAoProntoAtendimento,
  ...statusDeInternacoes,
  ...statusDosAgendamentos,
  ...sugestoes,
  ...tagsDosPacientes,
  ...tamanhosDePaginas,
  ...tarefa,
  ...teleconsulta,
  ...telefonesDeContatoDaTarefa,
  ...textosDaTarefa,
  ...textosLongosDaTarefa,
  ...tiposDeAgendamento,
  ...tiposDeAgendamento,
  ...tiposDeAgendamentoDoAgendamento,
  ...tiposDeAtendimentoDoNps,
  ...tiposDeAtendimentoDoProntuario,
  ...tiposDeAgendamentoDoUsuario,
  ...tiposDeAtendimentoDoUsuario,
  ...tiposDeAtividadeFisicaDeLazer,
  ...tiposDeConsultaExterna,
  ...tiposDeHorarios,
  ...tiposDeInternacao,
  ...tiposDeRepeticaoDeSerieDaAgenda,
  ...tiposDeSala,
  ...tiposDeTarefa,
  ...tiposDeUnidade,
  ...tratamentos,
  ...unidadesDeCalcioIonico,
  ...unidadesDoUsuario,
  ...unidadesFederativas,
  ...urgenciasDaTarefa,
  ...usuario,
  ...usuariosBloqueados,
  ...vacinas,
  ...vacinasDoPaciente,
  ...condicoesRelacionadasAosEstadosHabituais,
  ...intensidadesDasDoresDaTriagem,
  ...situacoesAssociadasAAlteracaoOuPerdaDaConsciencia,
  ...situacoesAssociadasAAlteracoesGastrointestinais,
  ...situacoesAssociadasADorNoPeito,
  ...situacoesAssociadasAQueda,
  ...situacoesAssociadasASintomasUrinarios,
  ...situacoesAssociadasAoLocalDaDorMusculoesqueletica,
  ...respostasAPressaoNoLocalDaHemorragia,
  ...respostasValidasSobreDiarreia,
  ...respostasValidasSobreVomito,
  ...resultadosDasTriagens,
  ...triagens,
}
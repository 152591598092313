import React from 'react'
import { Abas, Aba, ListaDeAbas, Paineis, Painel } from '../../atendimento/abas'
import { usePossuiAsPermissoes } from '../../../seguranca/permissao'
import * as permissoes from '../../../seguranca/permissoes'
import AvaliacaoDaSobrecargaDoCuidador from './avaliacao-da-sobrecarga-do-cuidador/'
import AvaliacoesDaSolidao from './avaliacao-da-solidao/'
import AvaliacoesFisicasEFuncionais from './avaliacao-fisica-e-funcional/'
import Casp16 from './casp-16/'
import Ivcf from './ivcf/'
import EscalaAmbientalDeRiscoDeQuedas from './escala-ambiental-de-risco-de-queda'
import MiniMental from './mini-mental'
import Phq9 from './phq9'

export default function Questionarios(props) {
  const {
    identificadorDoPaciente,
    idadeDoPaciente,
    questionarios: {
      avaliacoesDaSobrecargaDoCuidador,
      avaliacoesDaSolidao,
      avaliacoesFisicasEFuncionais,
      casps16,
      ivcfs,
      escalasAmbientaisDeRiscoDeQuedas,
      miniMentais,
      phqs9
    }
  } = props

  const podeListarIvcf = usePossuiAsPermissoes([permissoes.LISTAR_IVCFS_DO_PACIENTE])
  const podeListarAvaliacaoDaSobrecargaDoCuidador = usePossuiAsPermissoes([permissoes.LISTAR_AVALIACOES_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE])
  const podeListarCasp16 = usePossuiAsPermissoes([permissoes.LISTAR_CASPS_16_DO_PACIENTE])
  const podeListarAvaliacoesDaSolidao = usePossuiAsPermissoes([permissoes.LISTAR_AVALIACOES_DA_SOLIDAO_DO_PACIENTE])
  const podeListarAvaliacoesFisicasEFuncionais = usePossuiAsPermissoes([permissoes.LISTAR_AVALIACOES_FISICAS_E_FUNCIONAIS_DO_PACIENTE])
  const podeListarEscalasAmbientaisDeRiscoDeQuedas = usePossuiAsPermissoes([permissoes.LISTAR_ESCALAS_AMBIENTAIS_DE_RISCO_DE_QUEDAS_DO_PACIENTE])
  const podeListarMiniMentais = usePossuiAsPermissoes([permissoes.LISTAR_MINI_MENTAIS])
  const podeListarPhqs9 = usePossuiAsPermissoes([permissoes.LISTAR_PHQS9])

  const renderizarQuestionarios = () => {
    let abas = []
    let paineis = []

    //Abas
    const abaIvcf = <Aba key='aba-ivcf'>IVCF {ivcfs && ivcfs.length > 0 ? `(${ivcfs[0].resultado})` : ''}</Aba>
    const abaAvaliacaoDaSobrecargaDoCuidador = <Aba key='aba-zarit' title='Avaliação da Sobrecarga do Cuidador'>Escala Zarit {avaliacoesDaSobrecargaDoCuidador && avaliacoesDaSobrecargaDoCuidador.length > 0 ? `(${avaliacoesDaSobrecargaDoCuidador[0].pontuacao})` : ''}</Aba>
    const abaCasp16 = <Aba key='aba-casp16' title='Questionário: Jornada da Dor Crônica'>CASP-16 {casps16 && casps16.length > 0 ? `(${casps16[0].resultado})` : ''}</Aba>
    const abaAvaliacaoDaSolidao = <Aba key='aba-avaliacaoDaSolidao'>Avaliação da Solidão {avaliacoesDaSolidao && avaliacoesDaSolidao.length > 0 ? `(${avaliacoesDaSolidao[0].resultado})` : ''}</Aba>
    const abaEscalaAmbientalDeRiscoDeQuedas = <Aba key='aba-escalaAmbientalDeRiscoDeQuedas'>Escala Ambiental de Risco de Quedas</Aba>
    const abaAvaliacoesFisicasEFuncionais = <Aba key='aba-avaliacoesFisicasEFuncionais'>Avaliação Física e Funcional</Aba>
    const abaMiniMentais = <Aba key='aba-miniMentais'>Mini Mental</Aba>
    const abaPhq9 = <Aba key='aba-phq9'>PHQ9</Aba>

    //Painéis
    const painelIvcf = <Painel key='ivcf'><Ivcf identificadorDoPaciente={identificadorDoPaciente} idadeDoPaciente={idadeDoPaciente} ivcfsDoPaciente={ivcfs} /></Painel>
    const painelAvaliacaoDaSobrecargaDoCuidador = <Painel key='zarit'><AvaliacaoDaSobrecargaDoCuidador identificadorDoPaciente={identificadorDoPaciente} avaliacoesDoPaciente={avaliacoesDaSobrecargaDoCuidador} /></Painel>
    const painelCasp16 = <Painel key='casp-16'><Casp16 identificadorDoPaciente={identificadorDoPaciente} casps16DoPaciente={casps16} /></Painel>
    const painelAvaliacaoDaSolidao = <Painel key='avaliacaoDaSolidao'><AvaliacoesDaSolidao identificadorDoPaciente={identificadorDoPaciente} avaliacoesDaSolidaoDoPaciente={avaliacoesDaSolidao} /></Painel>
    const painelEscalaAmbientalDeRiscoDeQuedas = <Painel key='escalaAmbientalDeRiscoDeQuedas'><EscalaAmbientalDeRiscoDeQuedas identificadorDoPaciente={identificadorDoPaciente} escalasDoPaciente={escalasAmbientaisDeRiscoDeQuedas} /></Painel>
    const painelAvaliacoesFisicasEFuncionais = <Painel key='avaliacoesFisicasEFuncionais'><AvaliacoesFisicasEFuncionais identificadorDoPaciente={identificadorDoPaciente} avaliacoesFisicasEFuncionaisDoPaciente={avaliacoesFisicasEFuncionais} /></Painel>
    const painelMiniMentais = <Painel key='miniMentais'><MiniMental identificadorDoPaciente={identificadorDoPaciente} miniMentais={miniMentais} /></Painel>
    const painelPhq9 = <Painel key='phqs9'><Phq9 identificadorDoPaciente={identificadorDoPaciente} phqs9={phqs9} /></Painel>

    if (podeListarIvcf) {
      abas.push(abaIvcf)
      paineis.push(painelIvcf)
    }
    if (podeListarAvaliacaoDaSobrecargaDoCuidador) {
      abas.push(abaAvaliacaoDaSobrecargaDoCuidador)
      paineis.push(painelAvaliacaoDaSobrecargaDoCuidador)    
    }
    if (podeListarCasp16) {
      abas.push(abaCasp16)
      paineis.push(painelCasp16)
    }
    if (podeListarAvaliacoesDaSolidao) {
      abas.push(abaAvaliacaoDaSolidao)
      paineis.push(painelAvaliacaoDaSolidao)
    }
    if (podeListarEscalasAmbientaisDeRiscoDeQuedas) {
      abas.push(abaEscalaAmbientalDeRiscoDeQuedas)
      paineis.push(painelEscalaAmbientalDeRiscoDeQuedas)
    }
    if (podeListarAvaliacoesFisicasEFuncionais) {
      abas.push(abaAvaliacoesFisicasEFuncionais)
      paineis.push(painelAvaliacoesFisicasEFuncionais)
    }
    if (podeListarMiniMentais) {
      abas.push(abaMiniMentais)
      paineis.push(painelMiniMentais)
    }
    if (podeListarPhqs9) {
      abas.push(abaPhq9)
      paineis.push(painelPhq9)
    }

    return (
      <Abas>
        <r-grid columns-md={6} columns-lg={12} class='align-items-center'>
          <r-cell span={4} span-md={6} span-lg={12}>
            <ListaDeAbas className='tab'>{abas.map(aba => aba)}</ListaDeAbas>
          </r-cell>
        </r-grid>
        <Paineis>{paineis.map(painel => painel)}</Paineis>
      </Abas>
    )
  }

  return (
    <div className='form gestao-de-condicoes-clinicas'>
      <fieldset>
        {renderizarQuestionarios()}
      </fieldset>
    </div>
  )
}
import React, {useEffect, useState} from 'react'
import ReactTooltip from 'react-tooltip'
import Modal from 'react-modal'
import {Coluna, Tabela} from '../../../../tabela'
import {formatarDataEHoraParaFormatoLocal, formatarDataParaFormatoLocal} from '../../../../../bibliotecas/data'
import Spinner from '../../../../spinner'
import {useProntuario} from '../../../contexto'
import Permissao, {usePossuiAsPermissoes} from '../../../../seguranca/permissao'
import * as permissoes from '../../../../seguranca/permissoes'
import Formulario from './formulario'
import Resumo from './resumo'
import moment from 'moment'

import {
  LISTAR_ESCALAS_AMBIENTAIS_DE_RISCO_DE_QUEDAS_DO_PACIENTE,
  REMOVER_ESCALA_AMBIENTAL_DE_RISCO_DE_QUEDAS_DO_PACIENTE
} from '../../../../../acoes/tipos'

export default function EscalaAmbientalDeRiscoDeQuedas({ identificadorDoPaciente, escalasDoPaciente }) {
  const {
    listarEscalasAmbientaisDeRiscoDeQuedas,
    adicionarEscalaAmbientalDeRiscoDeQuedas,
    alterarEscalaAmbientalDeRiscoDeQuedas,
    removerEscalaAmbientalDeRiscoDeQuedas
  } = useProntuario()

  const [exibirFormulario, setExibirFormulario] = useState(false)
  const [exibirResumo, setExibirResumo] = useState(false)
  const [exibirExcluir, setExibirExcluir] = useState(false)
  const [selecionado, setSelecionado] = useState(null)

  const podeAdicionar = usePossuiAsPermissoes([permissoes.ADICIONAR_ESCALA_AMBIENTAL_DE_RISCO_DE_QUEDAS_DO_PACIENTE])

  useEffect(() => {
    if (escalasDoPaciente === undefined) {
      listarEscalasAmbientaisDeRiscoDeQuedas(identificadorDoPaciente)
    }
  }, [listarEscalasAmbientaisDeRiscoDeQuedas, identificadorDoPaciente, escalasDoPaciente])

  function abrirFormulario(item) {
    setSelecionado(item)
    setExibirFormulario(true)
  }

  function fecharFormulario() {
    setExibirFormulario(false)

    if (selecionado) {
      setSelecionado(null)
    }
  }

  function abrirResumo(item) {
    setSelecionado(item)
    setExibirResumo(true)
  }

  function fecharResumo() {
    setExibirResumo(false)

    if (selecionado) {
      setSelecionado(null)
    }
  }

  function abrirExcluir(item) {
    setSelecionado(item)
    setExibirExcluir(true)
  }

  function fecharExcluir() {
    setExibirExcluir(false)

    if (selecionado) {
      setSelecionado(null)
    }
  }

  const salvar = async item => {
    return selecionado ? await alterarEscalaAmbientalDeRiscoDeQuedas(identificadorDoPaciente, item) : await adicionarEscalaAmbientalDeRiscoDeQuedas(identificadorDoPaciente, item)
  }

  if (escalasDoPaciente === undefined) {
    return (
      <div className='table-items-expanded is-loading abas-qgcc'></div>
    )
  }

  return (
    <>
      <Modal
        isOpen={exibirFormulario}
        className='modal'
        contentLabel='Modal do formulário da Escala Ambiental de Risco de Quedas'
        style={{ content: { width: '1100px' } }}
      >
        <Formulario
          fechar={fecharFormulario}
          salvar={salvar}
          selecionado={selecionado}
          valores={recuperaValorInicialEscalaAmbientalDeRiscoDeQuedas(selecionado)}
        />
      </Modal>
      <Modal
        isOpen={exibirResumo}
        className='modal'
        contentLabel='Modal do resumo da Escala Ambiental de Risco de Quedas'
        style={{ content: { width: '1100px' } }}
      >
        <Resumo
          fechar={fecharResumo}
          valores={recuperaValorInicialEscalaAmbientalDeRiscoDeQuedas(selecionado)}
        />
      </Modal>
      <Modal
        isOpen={exibirExcluir}
        contentLabel='Modal para remoção da Escala Ambiental de Risco de Quedas'
        className='modal-vacinas'
      >
        <Remover
          selecionada={selecionado}
          remover={valor => removerEscalaAmbientalDeRiscoDeQuedas(identificadorDoPaciente, valor)}
          fechar={fecharExcluir}
        />
      </Modal>
      <r-cell span={4} span-md={6} span-lg={12}>
        <Spinner operacoes={[LISTAR_ESCALAS_AMBIENTAIS_DE_RISCO_DE_QUEDAS_DO_PACIENTE]}>
          {({ processando }) => (
            <Tabela
              acoes={[
                item =>
                  <Permissao key='remover' permissoes={[permissoes.REMOVER_ESCALA_AMBIENTAL_DE_RISCO_DE_QUEDAS_DO_PACIENTE]}>
                    <button
                      className='table-items__bt --remove'
                      title='Remover'
                      onClick={() => abrirExcluir(item)}
                      type='button'
                      key={`remove_${item.identificador}`}
                    >
                      <i className='icon icon-remove'></i>
                    </button>
                  </Permissao>
                ,
                item =>
                  <button
                    className='table-items__bt --edit'
                    title='Ver resumo'
                    onClick={() => abrirResumo(item)}
                    type='button'
                    key={`questionario_${item.identificador}`}
                  >
                    <i className='icon icon-doc-text'></i>
                  </button>
                ,
                item =>
                  <Permissao key='editar' permissoes={[permissoes.ALTERAR_ESCALA_AMBIENTAL_DE_RISCO_DE_QUEDAS_DO_PACIENTE]}>
                    <button
                      type='button'
                      onClick={() => abrirFormulario(item)}
                      className='table-items__bt --edit'
                      title='Editar'
                    >
                      <i className='icon icon-pencil'></i>
                    </button>
                  </Permissao>
                ,
                item =>
                  <div key='informacao'>
                    <button
                      data-tip
                      data-for={`info_${item.identificador}`}
                      key={`info_${item.identificador}`}
                      className='table-items__bt --edit'
                      type='button'
                    >
                      <i className='icon icon-info'></i>
                    </button>
                    <ReactTooltip
                      id={`info_${item.identificador}`}
                      place='top'
                      effect='solid'
                      type='info'
                      key={item.identificador}
                      className='tamanho-tooltip-diagnostico lh-spam'
                    >
                      <div
                        style={{
                          marginBottom: 8,
                          borderBottomStyle: 'solid',
                          borderBottomWidth: '1px',
                          borderBottomColor: 'rgb(232, 232, 232)'
                        }}><strong>Ultima alteração</strong></div>
                      <span>Usuário: {item.usuario.nome}</span> <br />
                      {item.usuario.profissao && <><span>Profissão: {item.usuario.profissao.nome}</span> <br /></>}
                      <span>Data e Hora: {formatarDataEHoraParaFormatoLocal(item.dataEHora)}</span> <br />
                    </ReactTooltip>
                  </div>
              ]}
              chave='identificador'
              className={`--secondary ${processando ? 'is-loading abas-qgcc' : ''}`}
              dados={escalasDoPaciente}
              exibirBotaoAdicionar={podeAdicionar}
              acaoDoBotaoAdicionar={abrirFormulario}
              mensagemTabelaVazia='Não existem registros da Escala Ambiental de Risco de Quedas.'
              classNameAdicionar='col-xs-6 col-lg-6'
            >
              <Coluna
                className='col-xs-6 col-lg-6'
                nome='Data'
                campoDeDados='data'
                exibirTitulo={true}
                principal={true}
                renderizar={item => formatarDataParaFormatoLocal(item.data)}
              />
            </Tabela>
          )}
        </Spinner>
      </r-cell>
    </>
  )
}

function Remover({ selecionada, remover, fechar }) {
  const excluirEscala = async () => {
    const excluiu = await remover(selecionada.identificador)
    if (excluiu) {
      fechar()
    }
  }

  return (
    <Spinner operacoes={[REMOVER_ESCALA_AMBIENTAL_DE_RISCO_DE_QUEDAS_DO_PACIENTE]}>
      {({ processando }) => (
        <r-grid columns-md={6} columns-lg={12}>
          <r-cell span={4} span-md={6} span-lg={12}>
            <div className={`form ${processando ? 'is-loading' : ''}`}>
              <fieldset className='form-group'>
                <h2>{`Tem certeza que deseja remover a Escala Ambiental de Risco de Quedas?`}</h2>
                <div className='form-choice-alerta my-1'>
                  <div className='form-choice-alerta__item'>
                    <i className='icon icon-attention-circled'></i>
                  </div>
                  <p className='form-choice-alerta__item'>
                    Essa ação não poderá ser desfeita após a confirmação.
                  </p>
                </div>
                <div className='list-btn'>
                  <button className='button --light' type='button' onClick={fechar}>Sair</button>
                  <button className='button --danger' type='button' onClick={excluirEscala}>Remover</button>
                </div>
              </fieldset>
            </div>
          </r-cell>
        </r-grid>
      )}
    </Spinner>
  )
}

const recuperaValorInicialEscalaAmbientalDeRiscoDeQuedas = selecionado => {
  if (selecionado) {
    return {
      ...selecionado
    }
  }

  return {
    identificador: '',
    data: moment().format('YYYY-MM-DD'),
    areasDeLocomocao: {
      asAreasDeLocomocaoSaoDesimpedidas: undefined,
      existemBarrasDeApoio: undefined,
      osRevestimentosSaoUniformesEOsTapetesSaoBemFixos: undefined,
    },
    iluminacao: {
      existeIluminacaoSuficienteParaClarearTodoOInteriorIncluindoDegraus: undefined,
      existemInterruptoresAcessiveisNaEntradaDosComodos: undefined,
    },
    quartoDeDormir: {
      oGuardaRoupaPossuiCabidesFacilmenteAcessiveis: undefined,
      existeCadeiraQuePermiteOPacienteSeAssentarParaSeVestir: undefined,
      aCamaPossuiBoaAltura: undefined,
    },
    banheiro: {
      aAreaDoChuveiroPossuiAntiderrapante: undefined,
      oBoxPossuiAberturaFacilOuSePossuiCortinaElaEstahBemFirme: undefined,
    },
    cozinha: {
      osArmariosSaoBaixosSemNecessidadeDoUsoDeEscada: undefined,
    },
    escada: {
      existeRevestimentoAntiderrapanteComMarcacaoAmarelaNoPrimeiroEUltimoDegrau: undefined,
      existeCorrimaoBilateralSolidoEProlongadoAlemDoPrimeiroEUltimoDegrau: undefined,
      osDegrausSaoUniformes: undefined,
    },
  }
}
import React, { useEffect, useMemo, useCallback } from 'react'
import Spinner from '../../../spinner'
import { formatarDataEHoraParaFormatoLocal } from '../../../../bibliotecas/data'
import InformacoesAdicionais from '../../componentes/informacoes-adicionais'
import { useUsuarioLogado } from '../../../seguranca/provedor-de-autenticacao'
import Permissao from '../../../seguranca/permissao'
import * as permissoes from '../../../seguranca/permissoes'
import { renderizarComponente } from './renderizar-componente'
import { renderizarComponenteObrigatorio } from './renderizar-componente-obrigatorio'

import {
  FINALIZAR_ATENDIMENTO,
  SALVAR_SECAO_DO_ATENDIMENTO,
  RECUPERAR_PRESCRICAO_PDF_NA_MEMED,
  RECUPERAR_CERTIFICADOS_EM_NUVEM_DO_USUARIO
} from '../../../../acoes/tipos'

export default function Resumo(props) {
  const {
    atendimento,
    secoes = [],
    finalizarAtendimento = false,
    assinarAtendimento = false,
    fecharModal,
    confirmarAtendimento,
    exibirTarefas,
    abrirAssinaturaComCertificadoFisico,
    abrirAssinaturaComCertificadoEmNuvem,
    recuperarCertificadosEmNuvemDoUsuario,
  } = props

  const { usuario } = useUsuarioLogado()

  useEffect(() => {
    if (usuario.certificadosEmNuvem.erro && recuperarCertificadosEmNuvemDoUsuario) {
      recuperarCertificadosEmNuvemDoUsuario()
    }
  }, [usuario.certificadosEmNuvem.erro, recuperarCertificadosEmNuvemDoUsuario])

  const tratarTitulos = useCallback((componentes = []) => {
    let tratados = []
    const filtrados = componentes.filter(x => x)

    filtrados.filter(x => x).forEach((componente, index) => {
      const proximo = filtrados.length > index ? filtrados[index + 1] : null
      const proximoEhUmTitulo = proximo && proximo.props.tipo.toUpperCase() === 'TITULO'
      const atualEhUmTitulo = componente.props.tipo.toUpperCase() === 'TITULO'
      const naoTemValor = !componente.props.valor || componente.props.valor.length === 0

      if (!atualEhUmTitulo && naoTemValor)
        return

      if (atualEhUmTitulo && (!proximo || proximoEhUmTitulo)) {
        return
      }

      tratados.push(componente)
    })

    return removerTitulosSemDados(tratados)
  }, [])

  const removerTitulosSemDados = componentesRenderizados => {
    const componentesRenderizadosFiltrados = componentesRenderizados.filter(x => x)
    let componentesSelecionados = []

    for (let i = 0; i < componentesRenderizadosFiltrados.length; i++) {
      const componente = componentesRenderizadosFiltrados[i]

      if (componente.props && componente.props.tipo && componente.props.tipo.toUpperCase() === 'TITULO') {
        const proximo = i + 1
        const proximoCampo = componentesRenderizadosFiltrados.length >= proximo ? componentesRenderizadosFiltrados[proximo] : null

        if (!proximoCampo || (proximoCampo.props && proximoCampo.props.tipo &&
          (proximoCampo.props.tipo.toUpperCase() === 'TITULO' || proximoCampo.props.tipo.toUpperCase() === 'PASSOMETRO' || proximoCampo.props.tipo.toUpperCase() === 'ANAMNESE'))) {
          continue
        }
      }

      componentesSelecionados.push(componente)
    }

    return componentesSelecionados
  }

  const secaoObrigatorioAoFinalizar = useMemo(() => {
    const abas = secoes.map(secao => {
      const { componentes } = secao
      return renderizarComponenteObrigatorio(componentes, atendimento)
    })

    return abas.reduce((acumulado, atual) => {
      if (atual && atual[0].props && atual[0].props.valor === null) {
        return acumulado.concat(atual)
      }

      return acumulado
    }, [])
  }, [atendimento, secoes])

  const atendimentoVazio = useMemo(() => {
    const todos = secoes && secoes.map(secao => {
      const { nome, componentes } = secao
      const componenteRenderizados = tratarTitulos(componentes.map(componente => renderizarComponente(componente, atendimento, nome)))

      if (componenteRenderizados.length > 0) {
        return true
      }

      return false
    })

    return todos && todos.some(t => t === true) ? false : <li>É necessário informar algum dado para finalizar o atendimento.</li>
  }, [atendimento, secoes, tratarTitulos])

  if (!atendimento) {
    return null
  }

  const renderizarSecao = (secao, atendimento) => {
    const { nome, componentes } = secao
    const componenteRenderizados = tratarTitulos(componentes.map(componente => renderizarComponente(componente, atendimento, nome)))

    if (componenteRenderizados.length === 0) {
      return null
    }

    return (
      <div className='mt-2' key={nome}>
        <fieldset>
          <h2 className='form-title nome-secao'>{nome}</h2>
          <r-grid columns-md={6} columns-lg={12}>
            {componenteRenderizados}
          </r-grid>
        </fieldset>
      </div>
    )
  }

  const impedirFinalizar = secaoObrigatorioAoFinalizar.length > 0 || atendimentoVazio

  const renderizarInformacoesAdicionais = informacoesAdicionais => {
    if (informacoesAdicionais.length === 0) return null

    return (
      <fieldset>
        <h2 className='form-title nome-secao'>Informações Adicionais</h2>
        <r-grid columns-md={6} columns-lg={12}>
          <r-cell span={4} span-md='row' span-lg='row'>
            {informacoesAdicionais.map((informacao, index) =>
              <React.Fragment key={index}>
                <div className='form-group'>
                  <pre className='description'>
                    <strong>Texto</strong>
                    <br />
                    {informacao.texto}
                  </pre>
                </div>
                {informacao.arquivos.length > 0 &&
                  <div className='form-group'>
                    <pre className='description my-1'>
                      <strong>Arquivos adicionados</strong>
                    </pre>
                    <InformacoesAdicionais
                      valor={informacao.arquivos || []}
                      atendimento={atendimento}
                    />
                    <br />
                  </div>
                }
                <em className='resumo-informacoes'>Adicionado por {informacao.usuario.nome} no dia {formatarDataEHoraParaFormatoLocal(informacao.dataEHora)}</em>
                <hr className='separator'></hr>
              </React.Fragment>
            )}
          </r-cell>
        </r-grid>
      </fieldset>
    )
  }

  return (
    <Spinner operacoes={[FINALIZAR_ATENDIMENTO, SALVAR_SECAO_DO_ATENDIMENTO, RECUPERAR_PRESCRICAO_PDF_NA_MEMED]}>
      {({ processando }) => (
        <div className={`box-agenda resumo ${processando && 'is-loading'}`}>
          <div className='form mt-2 resumo-do-atendimento'>
            {finalizarAtendimento &&
              <div className='finalizar-atendimento'>
                <h2 className='form-title'>Confirmar Atendimento</h2>
                <hr className='separator mt-1'></hr>
                <fieldset className='pb-0'>
                  <div className='form-choice-alerta'>
                    <div className='form-choice-alerta__item'>
                      <i className='icon icon-attention-circled'></i>
                    </div>
                    <div className='form-choice-alerta__item'>
                      O atendimento não poderá ser alterado após a confirmação.
                    </div>
                  </div>
                  {exibirTarefas &&
                    <div className='form-choice-alerta'>
                      <div className='form-choice-alerta__item'>
                        <i className='icon icon-attention-circled'></i>
                      </div>
                      <div className='form-choice-alerta__item'>
                        <ul>
                          <li>Perguntar se o paciente caiu.</li>
                          <li>Perguntar se o paciente foi ao Pronto Atendimento.</li>
                          <li>Perguntar se o paciente foi internado.</li>
                          <li>Caso tenha ocorrido algum agravo, avisar a enfermeira da célula para registro no campo específico.</li>
                        </ul>
                      </div>
                    </div>
                  }
                  {impedirFinalizar &&
                    <div className='form-choice-erro'>
                      <div className='form-choice-erro__item'>
                        <i className='icon icon-attention-circled'></i>
                      </div>
                      <div className='form-choice-erro__item'>
                        <ul>
                          {secaoObrigatorioAoFinalizar}
                          {atendimentoVazio}
                        </ul>
                      </div>
                    </div>
                  }
                  <p>{atendimento.paciente.nome}</p>
                  <hr className='separator mt-1 mb-1'></hr>
                </fieldset>
              </div>
            }
            {assinarAtendimento &&
              <div className='finalizar-atendimento'>
                <h2 className='form-title'>Assinar Atendimento de {atendimento.paciente.nome} em {formatarDataEHoraParaFormatoLocal(atendimento.inicio)}</h2>
                <hr className='separator mt-1'></hr>
              </div>
            }
            {secoes.map(secao => renderizarSecao(secao, atendimento))}
            {atendimento.informacoesAdicionais && renderizarInformacoesAdicionais(atendimento.informacoesAdicionais)}
            {finalizarAtendimento &&
              <div className='list-btn'>
                <button className='button --light' onClick={fecharModal}>Revisar</button>
                <button className={`button --light ${impedirFinalizar ? 'is-disabled' : ''}`} onClick={confirmarAtendimento} >Finalizar sem Assinar</button>
                <BotoesDeAssinatura
                  abrirAssinaturaComCertificadoFisico={abrirAssinaturaComCertificadoFisico}
                  abrirAssinaturaComCertificadoEmNuvem={abrirAssinaturaComCertificadoEmNuvem}
                  desabilitar={impedirFinalizar}
                  finalizar={true}
                  permissoes={permissoes}
                />
              </div>
            }
            {assinarAtendimento &&
              <div className='list-btn'>
                <button className='button --light' onClick={fecharModal}>Fechar</button>
                <BotoesDeAssinatura
                  abrirAssinaturaComCertificadoFisico={abrirAssinaturaComCertificadoFisico}
                  abrirAssinaturaComCertificadoEmNuvem={abrirAssinaturaComCertificadoEmNuvem}
                  permissoes={permissoes}
                />
              </div>
            }
          </div>
        </div>
      )}
    </Spinner>
  )
}

function BotoesDeAssinatura(props) {
  const {
    desabilitar = false,
    finalizar = false,
    permissoes,
    //abrirAssinaturaComCertificadoFisico,
    abrirAssinaturaComCertificadoEmNuvem
  } = props

  return (
    <Spinner operacoes={[RECUPERAR_CERTIFICADOS_EM_NUVEM_DO_USUARIO]}>
      {({ processando }) => (
        <Permissao permissoes={[permissoes.ASSINAR_ATENDIMENTO]}>
          <>
            {/* <button className='button --light' disabled={disabled} onClick={() => abrirAssinaturaComCertificadoFisico()}>{finalizar ? 'Finalizar e ' : ''}Assinar com Certificado Físico</button> */}
            <button className={`button --primary ${desabilitar ? 'is-disabled' : ''}`} onClick={() => abrirAssinaturaComCertificadoEmNuvem()}>{finalizar ? 'Finalizar e ' : ''}Assinar com Certificado em Nuvem</button>
            {processando &&
              <button className={`button --primary ${processando && 'is-loading'}`}>Carregando Certificados em Nuvem</button>
            }
          </>
        </Permissao>
      )}
    </Spinner>
  )
}
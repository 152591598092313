import React, { useEffect, useState } from 'react'
import Cabecalho from './cabecalho'
import Spinner from '../spinner'
import { Formulario as Form, Select, Toglle } from '../formik/formulario'
import { confirmAlert } from 'react-confirm-alert'
import { ordemDoServidor } from '../../bibliotecas/ordenacao'
import { usePossuiAsPermissoes } from '../seguranca/permissao'
import * as permissoes from '../seguranca/permissoes'
import { Lock } from '../../design/icones'
import {
  LISTAR_TIPOS_DE_ATENDIMENTO,
  LISTAR_TIPOS_DE_ATENDIMENTO_HABILITADOS_PARA_O_USUARIO,
  HABILITAR_TIPO_DE_ATENDIMENTO_DO_USUARIO,
  DESABILITAR_TIPO_DE_ATENDIMENTO_DO_USUARIO
} from '../../acoes/tipos'

export default function TiposDeAtendimento(props) {
  const {
    fotos,
    match,
    history,
    recuperarPorIdentificador,
    usuario = {},
    listarTiposDeAtendimento,
    listarTiposDeAtendimentoHabilitadosParaOUsuario,
    habilitarTipoDeAtendimentoDoUsuario,
    desabilitarTipoDeAtendimentoDoUsuario,
    tiposDeAtendimento: {
      tiposDeAtendimento,
      paginaDeDados,
      habilitados
    }
  } = props

  const { identificador } = match.params
  const [selecionado, setSelecionado] = useState('')
  const podeHabilitarTipoDeAtendimento = usePossuiAsPermissoes([permissoes.HABILITAR_TIPO_DE_ATENDIMENTO_PARA_O_USUARIO])
  const podeDesabilitarTipoDeAtendimento = usePossuiAsPermissoes([permissoes.DESABILITAR_TIPO_DE_ATENDIMENTO_PARA_O_USUARIO])

  useEffect(() => {
    listarTiposDeAtendimento()
    listarTiposDeAtendimentoHabilitadosParaOUsuario(identificador)
  }, [listarTiposDeAtendimento, listarTiposDeAtendimentoHabilitadosParaOUsuario, identificador])

  const recuperarTiposDeAtendimentoDoUsuario = dados => ({
    dados: Object.keys(dados)
      .map(key => dados[key])
      .filter(x => !habilitados.some(i => i === x.identificador))
      .sort(ordemDoServidor)
      .map(x => ({ identificador: x.identificador, nome: x.nome }))
  })

  const tiposDeAtendimentoDisponiveisParaOUsuario = tiposDeAtendimento && recuperarTiposDeAtendimentoDoUsuario(tiposDeAtendimento)

  const adicionar = async ({ privado }) => {
    const adicionou = await habilitarTipoDeAtendimentoDoUsuario(identificador, selecionado, privado)

    if (adicionou) {
      setSelecionado('')
      listarTiposDeAtendimentoHabilitadosParaOUsuario(identificador)
    }
  }

  const removerSelecionado = async item => {
    const removeu = await desabilitarTipoDeAtendimentoDoUsuario(identificador, item.tipo.identificador)

    if (removeu) {
      setSelecionado('')
      listarTiposDeAtendimentoHabilitadosParaOUsuario(identificador)
    }
  }

  const confirmarERemover = item => {
    confirmAlert({
      title: 'Confirmação',
      message: `Tem certeza que deseja excluir o tipo de atendimento: ${item.tipo.nome}?`,
      buttons: [{
        className: 'is-danger',
        label: 'Sim',
        onClick: () => removerSelecionado(item)
      }, {
        label: 'Não'
      }]
    })
  }

  return (
    <Spinner operacoes={[LISTAR_TIPOS_DE_ATENDIMENTO, LISTAR_TIPOS_DE_ATENDIMENTO_HABILITADOS_PARA_O_USUARIO, HABILITAR_TIPO_DE_ATENDIMENTO_DO_USUARIO, DESABILITAR_TIPO_DE_ATENDIMENTO_DO_USUARIO]}>
      <Form
        valoresIniciais={{
          tiposDeAtendimento: selecionado ? selecionado : '',
          privado: false,
        }}
        acao={adicionar}
        reinicializar={true}
      >
        <Cabecalho identificador={identificador} recuperarPorIdentificador={recuperarPorIdentificador} usuario={usuario} fotos={fotos} />
        <div className='mt-2 mb-4'>
          <div>
            <fieldset>
              <h2 className='form-title'>{podeHabilitarTipoDeAtendimento ? 'Adicionar Tipo de Atendimento' : 'Tipo de Atendimento'}</h2>
              <r-grid columns-md={6} columns-lg={12}>
                {podeHabilitarTipoDeAtendimento &&
                  <>
                    <r-cell span={4} span-md={4} span-lg={5}>
                      <Select
                        nome='tiposDeAtendimento'
                        campoCodigo='identificador'
                        campoDescricao='nome'
                        itens={tiposDeAtendimentoDisponiveisParaOUsuario.dados}
                        tabIndex={1}
                        titulo='Tipos de Atendimento *'
                        onChange={setSelecionado}
                      />
                    </r-cell>
                    <r-cell span={4} span-md={1} span-lg={1}>
                      <div className='form-toggle-list'>
                        <Toglle
                          className='form-toggle-2'
                          nome='privado'
                          titulo='Privado'
                        />
                      </div>
                    </r-cell>
                    <r-cell span={4} span-md={2} span-lg={2}>
                      <button
                        type='submit'
                        className={`button --primary w-100 mt-24 ${!selecionado && 'is-disabled'}`}
                        disabled={!selecionado}
                        tabIndex={3}
                      >
                        Adicionar
                      </button>
                    </r-cell>
                  </>
                }
                <r-cell span={4} span-md={6} span-lg={8}>
                  {paginaDeDados.dados &&
                    paginaDeDados.dados.map((tipoDeAtendimento, index) => (
                      <div className='form-choice' key={index}>
                        <div className='form-choice__item --full'>{tipoDeAtendimento.tipo.nome}</div>
                        {tipoDeAtendimento.privado && <Lock size='19' color='#528ce3' />}
                        {podeDesabilitarTipoDeAtendimento && <button className='form-choice__bt-close' type='button' aria-label='Fechar' onClick={() => confirmarERemover(tipoDeAtendimento)}><i className='icon icon-close'></i></button>}
                      </div>
                    ))}
                </r-cell>
              </r-grid>
            </fieldset>
            <div className='list-btn'>
              <button
                className='button --light'
                type='button'
                onClick={() => history.push(`/configuracoes/usuarios`)}
              >
                Voltar
              </button>
            </div>
          </div>
        </div>
      </Form>
    </Spinner>
  )
}
import React, { useEffect, useState } from 'react'
import ReactTooltip from 'react-tooltip'
import { Coluna, Tabela } from '../../../../tabela'
import { formatarDataEHoraParaFormatoLocal, formatarDataParaFormatoLocal } from '../../../../../bibliotecas/data'
import Spinner from '../../../../spinner'
import { useProntuario } from '../../../contexto'
import Permissao, { usePossuiAsPermissoes } from '../../../../seguranca/permissao'
import * as permissoes from '../../../../seguranca/permissoes'
import Modal from 'react-modal'
import moment from 'moment'
import Formulario from './formulario'
import Resumo from './resumo'

import { LISTAR_AVALIACOES_FISICAS_E_FUNCIONAIS_DO_PACIENTE, REMOVER_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE } from '../../../../../acoes/tipos'

export default function AvaliacaoFisicaEFuncional({ identificadorDoPaciente, avaliacoesFisicasEFuncionaisDoPaciente }) {
  const {
    listarDuracoesDasDores,
    duracoesDasDores,
    listarIntensidadesDasDores,
    intensidadesDasDores,
    listarLocaisDasDores,
    locaisDasDores,    
    listarTiposDeAtividadeFisicaDeLazer,
    tiposDeAtividadeFisicaDeLazer,
    listarExecucoesDoTesteDeFisioterapia,
    execucoesDoTesteDeFisioterapia,
    listarAvaliacoesFisicasEFuncionais,
    adicionarAvaliacaoFisicaEFuncional,
    alterarAvaliacaoFisicaEFuncional,
    removerAvaliacaoFisicaEFuncional
  } = useProntuario()

  const [exibirFormulario, setExibirFormulario] = useState(false)
  const [exibirResumo, setExibirResumo] = useState(false)
  const [exibirExcluir, setExibirExcluir] = useState(false)
  const [selecionado, setSelecionado] = useState(null)

  const podeAdicionar = usePossuiAsPermissoes([permissoes.ADICIONAR_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE])

  useEffect(() => {
    if (avaliacoesFisicasEFuncionaisDoPaciente === undefined) {
      listarAvaliacoesFisicasEFuncionais(identificadorDoPaciente)
    }
  }, [listarAvaliacoesFisicasEFuncionais, identificadorDoPaciente, avaliacoesFisicasEFuncionaisDoPaciente])

  useEffect(() => { listarDuracoesDasDores() }, [listarDuracoesDasDores])
  useEffect(() => { listarIntensidadesDasDores() }, [listarIntensidadesDasDores])
  useEffect(() => { listarLocaisDasDores() }, [listarLocaisDasDores])
  useEffect(() => { listarTiposDeAtividadeFisicaDeLazer() }, [listarTiposDeAtividadeFisicaDeLazer])
  useEffect(() => { listarExecucoesDoTesteDeFisioterapia() }, [listarExecucoesDoTesteDeFisioterapia])

  function abrirFormulario(item) {
    setSelecionado(item)
    setExibirFormulario(true)
  }

  function fecharFormulario() {
    setExibirFormulario(false)

    if (selecionado) {
      setSelecionado(null)
    }
  }

  function abrirResumo(item) {
    setSelecionado(item)
    setExibirResumo(true)
  }

  function fecharResumo() {
    setExibirResumo(false)

    if (selecionado) {
      setSelecionado(null)
    }
  }

  function abrirExcluir(item) {
    setSelecionado(item)
    setExibirExcluir(true)
  }

  function fecharExcluir() {
    setExibirExcluir(false)

    if (selecionado) {
      setSelecionado(null)
    }
  }

  function formatarMinutosParaTimeSpan(valor) {
    if (!valor) {
      return null
    }
    
    return moment.utc(moment.duration(valor, 'minutes').asMilliseconds()).format('HH:mm:ss')
  }

  function formatarSegundosParaTimeSpan(valor) {
    if (!valor) {
      return null
    }

    return moment.utc(moment.duration(valor, 'seconds').asMilliseconds()).format('HH:mm:ss.SS')
  }
  
  const salvar = async item => {
    const valores = {
      ...item,
      localDaProteseMetalica: item.localDaProteseMetalica ? item.localDaProteseMetalica : null,
      atividadeFisicaDeLazer: item.atividadeFisicaDeLazer.realizaAtividadeFisicaDeLazer === undefined ? null : {
        ...item.atividadeFisicaDeLazer,
        tempoPorSemana: formatarMinutosParaTimeSpan(item.atividadeFisicaDeLazer.tempoPorSemana)
      },
      dor: item.dor.dor === undefined ? null : {
        ...item.dor,
        duracao: item.dor.duracao ? item.dor.duracao : null,
        intensidade: item.dor.intensidade ? item.dor.intensidade : null,        
      },
      quedas: (!item.quedas.numeroDeQuedasNosUltimos12Meses && item.quedas.medoDeCair === undefined) ? null : {
        numeroDeQuedasNosUltimos12Meses: !item.quedas.numeroDeQuedasNosUltimos12Meses ? null : item.quedas.numeroDeQuedasNosUltimos12Meses,
        medoDeCair: item.quedas.medoDeCair === undefined ? null : item.quedas.medoDeCair
      },
      tug: !item.tug.execucao ? null : {
        ...item.tug,
        tempo: formatarSegundosParaTimeSpan(item.tug.tempo)
      },
      sentaELevanta5Vezes: !item.sentaELevanta5Vezes.execucao ? null : {
        ...item.sentaELevanta5Vezes,
        tempo: formatarSegundosParaTimeSpan(item.sentaELevanta5Vezes.tempo)
      },
      velocidadeDaMarchaDe4Metros: !item.velocidadeDaMarchaDe4Metros.execucao ? null : {
        ...item.velocidadeDaMarchaDe4Metros,
        tempo: formatarSegundosParaTimeSpan(item.velocidadeDaMarchaDe4Metros.tempo)
      },
      equilibrio: (!item.equilibrio.romberg && !item.equilibrio.semiTamdem) ? null : {
        ...item.equilibrio,
        romberg: !item.equilibrio.romberg ? null : item.equilibrio.romberg,
        semiTamdem: !item.equilibrio.semiTamdem ? null : item.equilibrio.semiTamdem
      }
    }

    return selecionado ? await alterarAvaliacaoFisicaEFuncional(identificadorDoPaciente, valores) : await adicionarAvaliacaoFisicaEFuncional(identificadorDoPaciente, valores)
  }

  if (avaliacoesFisicasEFuncionaisDoPaciente === undefined) {
    return (
      <div className='table-items-expanded is-loading abas-qgcc'></div>
    )
  }

  return (
    <>
      <Modal
        isOpen={exibirFormulario}
        className='modal'
        contentLabel='Modal do formulário de Avaliação Física e Funcional'
        style={{ content: { width: '1100px' } }}
      >
        <Formulario
          fechar={fecharFormulario}
          salvar={salvar}
          selecionado={selecionado}
          valores={recuperaValorInicialAvaliacaoFisicaEFuncional(selecionado)}
          duracoesDasDores={duracoesDasDores}
          intensidadesDasDores={intensidadesDasDores}
          locaisDasDores={locaisDasDores}
          tiposDeAtividadeFisicaDeLazer={tiposDeAtividadeFisicaDeLazer}
          execucoesDoTesteDeFisioterapia={execucoesDoTesteDeFisioterapia}
        />
      </Modal>
      <Modal
        isOpen={exibirResumo}
        className='modal'
        contentLabel='Modal do resumo de Avaliação Física e Funcional'
        style={{ content: { width: '1100px' } }}
      >
        <Resumo
          fechar={fecharResumo}
          valores={recuperaValorInicialAvaliacaoFisicaEFuncional(selecionado)}
          duracoesDasDores={duracoesDasDores}
          locaisDasDores={locaisDasDores}
          tiposDeAtividadeFisicaDeLazer={tiposDeAtividadeFisicaDeLazer}
          execucoesDoTesteDeFisioterapia={execucoesDoTesteDeFisioterapia}
        />
      </Modal>
      <Modal
        isOpen={exibirExcluir}
        contentLabel='Modal para remoção do Avaliação Física e Funcional'
        className='modal-vacinas'
      >
        <Remover
          selecionada={selecionado}
          remover={valor => removerAvaliacaoFisicaEFuncional(identificadorDoPaciente, valor)}
          fechar={fecharExcluir}
        />
      </Modal>
      <r-cell span={4} span-md={6} span-lg={12}>
        <Spinner operacoes={[LISTAR_AVALIACOES_FISICAS_E_FUNCIONAIS_DO_PACIENTE]}>
          {({ processando }) => (
            <Tabela
              acoes={[
                item =>
                  <Permissao key='remover' permissoes={[permissoes.REMOVER_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE]}>
                    <button
                      className='table-items__bt --remove'
                      title='Remover'
                      onClick={() => abrirExcluir(item)}
                      type='button'
                      key={`remove_${item.identificador}`}
                    >
                      <i className='icon icon-remove'></i>
                    </button>
                  </Permissao>
                ,
                item =>
                  <button
                    className='table-items__bt --edit'
                    title='Ver resumo'
                    onClick={() => abrirResumo(item)}
                    type='button'
                    key={`questionario_${item.identificador}`}
                  >
                    <i className='icon icon-doc-text'></i>
                  </button>
                ,
                item =>
                  <Permissao key='editar' permissoes={[permissoes.ALTERAR_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE]}>
                    <button
                      type='button'
                      onClick={() => abrirFormulario(item)}
                      className='table-items__bt --edit'
                      title='Editar'
                    >
                      <i className='icon icon-pencil'></i>
                    </button>
                  </Permissao>
                ,
                item =>
                  <div key='informacao'>
                    <button
                      data-tip
                      data-for={`info_${item.identificador}`}
                      key={`info_${item.identificador}`}
                      className='table-items__bt --edit'
                      type='button'
                    >
                      <i className='icon icon-info'></i>
                    </button>
                    <ReactTooltip
                      id={`info_${item.identificador}`}
                      place='top'
                      effect='solid'
                      type='info'
                      key={item.identificador}
                      className='tamanho-tooltip-diagnostico lh-spam'
                    >
                      <div
                        style={{
                          marginBottom: 8,
                          borderBottomStyle: 'solid',
                          borderBottomWidth: '1px',
                          borderBottomColor: 'rgb(232, 232, 232)'
                        }}><strong>Ultima alteração</strong></div>
                      <span>Usuário: {item.usuario.nome}</span> <br />
                      {item.usuario.profissao && <><span>Profissão: {item.usuario.profissao.nome}</span> <br /></>}
                      <span>Data e Hora: {formatarDataEHoraParaFormatoLocal(item.dataEHora)}</span> <br />
                    </ReactTooltip>
                  </div>
              ]}
              chave='identificador'
              className={`--secondary ${processando ? 'is-loading abas-qgcc' : ''}`}
              dados={avaliacoesFisicasEFuncionaisDoPaciente}
              exibirBotaoAdicionar={podeAdicionar}
              acaoDoBotaoAdicionar={abrirFormulario}
              mensagemTabelaVazia='Não existem registros de Avaliação Física e Funcional.'
              classNameAdicionar='col-xs-6 col-lg-6'
            >
              <Coluna
                className='col-xs-6 col-lg-6'
                nome='Data'
                campoDeDados='data'
                exibirTitulo={true}
                principal={true}
                renderizar={item => formatarDataParaFormatoLocal(item.data)}
              />
            </Tabela>
          )}
        </Spinner>
      </r-cell>
    </>
  )
}

function Remover({ selecionada, remover, fechar }) {
  const excluirAvaliacaoFisicaEFuncional = async () => {
    const excluiu = await remover(selecionada.identificador)
    if (excluiu) {
      fechar()
    }
  }

  return (
    <Spinner operacoes={[REMOVER_AVALIACAO_FISICA_E_FUNCIONAL_DO_PACIENTE]}>
      {({ processando }) => (
        <r-grid columns-md={6} columns-lg={12}>
          <r-cell span={4} span-md={6} span-lg={12}>
            <div className={`form ${processando ? 'is-loading' : ''}`}>
              <fieldset className='form-group'>
                <h2>{`Tem certeza que deseja remover a Avaliação Física e Funcional?`}</h2>
                <div className='form-choice-alerta my-1'>
                  <div className='form-choice-alerta__item'>
                    <i className='icon icon-attention-circled'></i>
                  </div>
                  <p className='form-choice-alerta__item'>
                    Essa ação não poderá ser desfeita após a confirmação.
                  </p>
                </div>
                <div className='list-btn'>
                  <button className='button --light' type='button' onClick={fechar}>Sair</button>
                  <button className='button --danger' type='button' onClick={excluirAvaliacaoFisicaEFuncional}>Remover</button>
                </div>
              </fieldset>
            </div>
          </r-cell>
        </r-grid>
      )}
    </Spinner>
  )
}

function formatarTimeSpanParaMinutos(valor) {
  if (!valor) {
    return undefined
  }

  return moment.duration(valor).asMinutes()
}

function formatarSegundosParaTimeSpan(valor) {
  if (!valor) {
    return undefined
  }

  return moment.duration(valor).asSeconds()
}

const recuperaValorInicialAvaliacaoFisicaEFuncional = selecionado => {
  if (selecionado) {
    return {
      ...selecionado,
      localDaProteseMetalica: selecionado.localDaProteseMetalica ? selecionado.localDaProteseMetalica : '',
      atividadeFisicaDeLazer: {
        ...selecionado.secaoAtividadesFisicasDeLazer,
        tempoPorSemana: formatarTimeSpanParaMinutos(selecionado.secaoAtividadesFisicasDeLazer?.tempoPorSemana),
        tipos: selecionado.secaoAtividadesFisicasDeLazer === null ? [] : selecionado.secaoAtividadesFisicasDeLazer.tipos
      },
      dor: {
        ...selecionado.secaoDor,
        locais: selecionado.secaoDor === null ? [] : selecionado.secaoDor.locais
      },
      quedas: {
        ...selecionado.secaoQuedas
      },
      tug: {
        ...selecionado.tug,
        tempo: formatarSegundosParaTimeSpan(selecionado.tug?.tempo),
      },
      sentaELevanta5Vezes: {
        ...selecionado.sentaELevanta5Vezes,
        tempo: formatarSegundosParaTimeSpan(selecionado.sentaELevanta5Vezes?.tempo),
      },
      velocidadeDaMarchaDe4Metros: {
        ...selecionado.velocidadeDaMarchaDe4Metros,
        tempo: formatarSegundosParaTimeSpan(selecionado.velocidadeDaMarchaDe4Metros?.tempo),
      },
      equilibrio: {
        ...selecionado.secaoEquilibrio,
      }
    }
  }

  return {
    identificador: '',
    data: moment().format('YYYY-MM-DD'),
    queixaPrincipal: '',
    deambulaComAuxilioDeDispositivo: undefined,
    marcapasso: undefined,
    proteseMetalica: undefined,
    localDaProteseMetalica: '',
    atividadeFisicaDeLazer: {
      realizaAtividadeFisicaDeLazer: undefined,
      tempoPorSemana: undefined,
      tipos: []
    },
    dor: {
      dor: undefined,
      locais: [],
      duracao: undefined,
      intensidade: undefined
    },
    problemaImpedeRealizacaoDeAtividadesNormais: undefined,
    quedas: {
      numeroDeQuedasNosUltimos12Meses: undefined,
      medoDeCair: undefined
    },
    tug: {
      execucao: '',
      tempo: undefined
    },
    sentaELevanta5Vezes: {
      execucao: '',
      tempo: undefined
    },
    velocidadeDaMarchaDe4Metros: {
      execucao: '',
      tempo: undefined
    },
    equilibrio: {
      romberg: undefined,
      semiTamdem: undefined
    }
  }
}
import api from '../../api'
import notificacao from '../../bibliotecas/notificacao'
import { adicionarNotificacoesDaApi } from '../notificacoes'
import acoes from '../index'

import {
  FAZER_DOWNLOAD_DO_ARQUIVO_DE_ATENDIMENTO,
  FEZ_DOWNLOAD_DO_ARQUIVO_DE_ATENDIMENTO,
  ERRO_AO_FAZER_DOWNLOAD_DO_ARQUIVO_DE_ATENDIMENTO,
  CARREGAR_DETALHES_DO_ATENDIMENTO_FINALIZADO,
  CARREGOU_DETALHES_DO_ATENDIMENTO_FINALIZADO,
  ERRO_AO_CARREGAR_DETALHES_DO_ATENDIMENTO_FINALIZADO,
  LISTAR_ATENDIMENTOS_FINALIZADOS,
  LISTOU_ATENDIMENTOS_FINALIZADOS,
  ERRO_AO_LISTAR_ATENDIMENTOS_FINALIZADOS,
  ADICIONAR_INFORMACOES,
  ADICIONOU_INFORMACOES,
  ERRO_AO_ADICIONAR_INFORMACOES,
  SELECIONAR_ATENDIMENTO_DA_TIMELINE,
  ALTERAR_TERMO_DA_TIMELINE,
  MARCAR_ATENDIMENTO_COMO_PRIVADO
} from '../tipos'

const fezDownloadDoArquivoDeAtendimento = conteudo => ({
  type: FEZ_DOWNLOAD_DO_ARQUIVO_DE_ATENDIMENTO,
  conteudo,
})

const erroAoFazerDownloadDoArquivoDeAtendimento = (erro, parametros) => ({
  type: ERRO_AO_FAZER_DOWNLOAD_DO_ARQUIVO_DE_ATENDIMENTO,
  erro,
  parametros
})

export const fazerDownloadDoArquivoDeAtendimento = parametrosParaDownload => async dispatch => {
  dispatch({ type: FAZER_DOWNLOAD_DO_ARQUIVO_DE_ATENDIMENTO })

  try {
    let resultado = await api.fazerDownloadDoArquivoDeAtendimento(parametrosParaDownload)

    dispatch(fezDownloadDoArquivoDeAtendimento(resultado.data))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return resultado.data
  } catch (erro) {
    dispatch(erroAoFazerDownloadDoArquivoDeAtendimento(erro, { parametrosParaDownload }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível fazer o download do arquivo de atendimento.')))

    return null
  }
}

const erroAoCarregarDetalhesDoAtendimentoFinalizado = (erro, identificadorDoPaciente, identificadorDoAtendimento) => ({
  type: ERRO_AO_CARREGAR_DETALHES_DO_ATENDIMENTO_FINALIZADO,
  identificadorDoPaciente,
  identificadorDoAtendimento,
  erro,
})

const carregouDetalhesDoAtendimentoFinalizado = (identificadorDoPaciente, atendimento, secoes) => ({
  type: CARREGOU_DETALHES_DO_ATENDIMENTO_FINALIZADO,
  atendimento,
  identificadorDoPaciente,
  secoes
})

export const carregarDetalhesDoAtendimentoFinalizado = (identificadorDoPaciente, identificadorDoAtendimento, tipo) => async dispatch => {
  dispatch({ type: CARREGAR_DETALHES_DO_ATENDIMENTO_FINALIZADO, identificadorDoAtendimento, identificadorDoPaciente })

  try {
    const resultado = await api.recuperarAtendimento(identificadorDoPaciente, identificadorDoAtendimento)
    const atendimento = resultado.data.dados

    const versao = await dispatch(acoes.recuperarVersaoDoTipoDeAtendimento(tipo.identificador, atendimento.tipo.versao))

    dispatch(adicionarNotificacoesDaApi(resultado))
    dispatch(carregouDetalhesDoAtendimentoFinalizado(identificadorDoPaciente, atendimento, versao.secoes))
    dispatch(selecionarAtendimentoDaTimeline(atendimento))

    return atendimento
  } catch (erro) {
    dispatch(erroAoCarregarDetalhesDoAtendimentoFinalizado(erro, identificadorDoPaciente, identificadorDoAtendimento))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível carregar os detalhes do atendimento.')))

    return false
  }
}

const listouAtendimentosFinalizados = (atendimentos, identificadorDoPaciente) => ({
  type: LISTOU_ATENDIMENTOS_FINALIZADOS,
  atendimentos,
  identificadorDoPaciente,
})

const erroAoListarAtendimentosFinalizados = (erro, parametros) => ({
  type: ERRO_AO_LISTAR_ATENDIMENTOS_FINALIZADOS,
  erro,
  parametros
})

export const listarAtendimentosFinalizados = identificadorDoPaciente => async dispatch => {
  dispatch({ type: LISTAR_ATENDIMENTOS_FINALIZADOS, identificadorDoPaciente })

  try {
    const resultado = await api.listarAtendimentosFinalizados(identificadorDoPaciente)

    dispatch(listouAtendimentosFinalizados(resultado.data.dados.dados, identificadorDoPaciente))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoListarAtendimentosFinalizados(erro, { identificadorDoPaciente }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível listar os atendimentos finalizados.')))
  }
}

const adicionouInformacoes = (identificadorDoAtendimento, informacoesAdicionais, identificadorDoPaciente) => ({
  type: ADICIONOU_INFORMACOES,
  identificadorDoAtendimento,
  informacoesAdicionais,
  identificadorDoPaciente
})

const erroAoAdicionarInformacoes = (erro, parametros) => ({
  type: ERRO_AO_ADICIONAR_INFORMACOES,
  erro,
  parametros
})

export const adicionarInformacoes = (identificadorDoPaciente, atendimento, informacoes) => async dispatch => {
  dispatch({ type: ADICIONAR_INFORMACOES })

  try {
    const resultado = await api.adicionarInformacoes(atendimento, informacoes)

    dispatch(adicionouInformacoes(atendimento.identificador, resultado.data.dados, identificadorDoPaciente))
    dispatch(adicionarNotificacoesDaApi(resultado))

    return true
  } catch (erro) {
    dispatch(erroAoAdicionarInformacoes(erro, { identificadorDoPaciente, atendimento, informacoes }))
    dispatch(adicionarNotificacoesDaApi(erro, notificacao.deErro('Não foi possível adicionar informações.')))

    return false
  }
}

export const alterarTermoDaTimeline = (identificadorDoPaciente, termo) => ({
  type: ALTERAR_TERMO_DA_TIMELINE,
  identificadorDoPaciente,
  termo
})

export const selecionarAtendimentoDaTimeline = atendimento => ({
  type: SELECIONAR_ATENDIMENTO_DA_TIMELINE,
  atendimento
})

export const marcarAtendimentoComoPrivado = (identificadorDoPaciente, identificadorDoAtendimento, profissional) => ({
  type: MARCAR_ATENDIMENTO_COMO_PRIVADO,
  identificadorDoPaciente,
  identificadorDoAtendimento,
  profissional,
})


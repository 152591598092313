import { connect } from 'react-redux'
import acoes from '../../acoes'
import Usuarios from './index'
import { formatarCpf } from '../../bibliotecas/formatacao'

const recuperarFotos = (dados = [], imagens = {}) => {
  let fotos = {}

  dados.filter(x => x.foto).forEach(item => {
    fotos[item.foto] = imagens[item.foto]
  })

  return fotos
}

const mapStateToProps = state => {
  const usuarioAtual = state.usuarios.itemAtual

  let usuario = {}

  if (usuarioAtual) {
    const possuiConselho = usuarioAtual.profissao && usuarioAtual.profissao.conselho

    usuario.bytesDaFoto = usuarioAtual.foto ? state.imagens[usuarioAtual.foto] : null
    usuario.ativo = usuarioAtual.ativo
    usuario.conselho = possuiConselho ? usuarioAtual.profissao.conselho.nome : ''
    usuario.cpf = usuarioAtual.cpf ? formatarCpf(usuarioAtual.cpf, 11) : ''
    usuario.email = usuarioAtual.email
    usuario.especialidades = usuarioAtual.profissao ? usuarioAtual.profissao.especialidades : []
    usuario.foto = usuarioAtual.foto
    usuario.identificador = usuarioAtual.identificador
    usuario.informarDadosProfissionais = (usuarioAtual.profissao && usuarioAtual.profissao.nome) ? true : false
    usuario.nome = usuarioAtual.nome
    usuario.numeroDoRegistroNoConselhoProfissional = possuiConselho ? usuarioAtual.profissao.conselho.numeroDoRegistro : ''
    usuario.observacoes = usuarioAtual.observacoes
    usuario.profissao = usuarioAtual.profissao ? usuarioAtual.profissao.nome : ''
    usuario.sexo = usuarioAtual.sexo
    usuario.tratamento = usuarioAtual.tratamento
    usuario.telefone1 = usuarioAtual.telefone1
    usuario.telefone2 = usuarioAtual.telefone2
    usuario.telefone3 = usuarioAtual.telefone3
    usuario.unidadeFederativaDoConselhoProfissional = possuiConselho ? usuarioAtual.profissao.conselho.unidadeFederativa : ''
  }

  return {
    agenda: state.usuarios.itemAtual.agenda,
    configuracoes: state.usuarios.itemAtual.configuracoes.configuracoes,
    filtros: state.usuarios.filtros,
    fotos: recuperarFotos(state.usuarios.paginaDeDados.dados, state.imagens),
    identificadorDoUsuarioLogado: state.login.logado.identificador,
    logins: state.login.logins,
    ordenacao: state.usuarios.ordenacao,
    paginaDeDados: state.usuarios.paginaDeDados,
    perfil: state.usuarios.itemAtual.perfil,
    profissoes: state.profissoes.itens,
    sexos: state.sexos,
    tiposDeAtendimento: state.usuarios.itemAtual.tiposDeAtendimento,
    tiposDeHorarios: state.tiposDeHorarios,    
    tratamentos: state.tratamentos,
    unidades: state.usuarios.itemAtual.unidade,
    unidadesFederativas: state.unidadesFederativas,
    feriados: state.feriados.paginaDeDados.dados,
    usuario,
  }
}

export default connect(mapStateToProps, acoes)(Usuarios)
import {
  ABRIR_TELECONSULTA_NO_PRONTUARIO,
  CANCELOU_ATENDIMENTO,
  FECHAR_TELECONSULTA_NO_PRONTUARIO,
  FINALIZAR_ATENDIMENTO,
  INICIAR_ATENDIMENTO,
} from '../../acoes/tipos'

const estadoInicial = false

export default (state = estadoInicial, action) => {
  switch (action.type) {
    case ABRIR_TELECONSULTA_NO_PRONTUARIO: {
      return action.identificadorDoAtendimento
    }

    case CANCELOU_ATENDIMENTO:
    case FECHAR_TELECONSULTA_NO_PRONTUARIO:
    case INICIAR_ATENDIMENTO:
    case FINALIZAR_ATENDIMENTO: {
      return estadoInicial
    }

    default: {
      return state
    }
  }
}
import React from 'react'
import { MultiplaEscolha } from '../../../../formik/formulario'
import { formatarDataParaFormatoLocal } from '../../../../../bibliotecas/data'

const renderizarTitulo = valor => valor.descricao

export default function Resumo({ valores, fechar }) {
  const {
    versao,
    data,
    autoPercepcaoDaSaude,
    atividadesDeVidaDiaria,
    cognicao,
    humor,
    idadeDoPaciente,
    mobilidade,
    comunicacao,
    comorbidadesMultiplas,
    moradia,
    resultado
  } = valores

  if (!resultado && resultado !== 0) return null

  if (versao === '1') {
    return (
      <r-cell span={4} span-md='row' span-lg='row'>
        <div className='form form-group'>
          <pre className='description'>
            <strong>Resultado: </strong>
            {resultado}
          </pre>
        </div>
      </r-cell>
    )
  }

  return (
    <div className='form form-group'>
      <fieldset>
        <h2 className='form-title'>Resumo do IVCF</h2>
        <Paciente paciente={valores.paciente} />
        <Data data={data} />
        <br />
        <r-grid columns-md={6} columns-lg={12} class='componente-ivcf'>
          <Cabecalho />
          <Idade valor={idadeDoPaciente} />
          <AutoPercepcaoDaSaude valor={autoPercepcaoDaSaude} />
          <AtividadesDeVidaDiaria valor={atividadesDeVidaDiaria} />
          <Cognicao valor={cognicao} />
          <Humor valor={humor} />
          <Mobilidade valor={mobilidade} />
          <Comunicacao valor={comunicacao} />
          <ComorbidadesMultiplas valor={comorbidadesMultiplas} />
          <r-cell span={4} span-md={6} span-lg={12}>
            {(resultado || resultado === 0) &&
              <div className='resultado-questionario'>
                <div><strong>RESULTADO</strong> (0 - 40 PONTOS):</div>
                <div><strong>{resultado}</strong></div>
              </div>
            }
          </r-cell>
        </r-grid>
        {(!valores.versao || valores.versao === '3') && 
          <>
            <br />
            <r-grid columns-md={6} columns-lg={12} class='componente-ivcf'>
              <CabecalhoComplementar />
              <Moradia valor={moradia} />
            </r-grid>
          </>
        }
        <div className='list-btn'>
          <button type='button' className='button --light' onClick={() => fechar()}>Fechar</button>
        </div>
      </fieldset>
    </div>
  )
}

function Paciente({ paciente }) {
  if (!paciente) return null
  return <div className='componente-zarit paciente-leitura mb-1'><strong>Paciente:</strong> {paciente.nome}</div>
}

function Data({ data }) {
  if (!data) return null
  return <div className='componente-zarit paciente-leitura'><strong>Data:</strong> {formatarDataParaFormatoLocal(data)}</div>
}

function Cabecalho() {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class='cabecalho'>
      <div className='titulo-ivcf'>
        <strong>ÍNDICE DE VULNERABILIDADE CLÍNICO-FUNCIONAL-20</strong>
      </div>
      <div className='titulo-pontuacao'><strong>Pontuação</strong></div>
    </r-cell>
  )
}

function Idade({ valor = {} }) {
  const { idade, resultado } = valor

  return (
    <r-cell span={4} span-md={6} span-lg={12} class='idade'>
      <div className='agrupamento'><strong>IDADE</strong></div>
      <div className='pergunta'>
        <strong>1. Qual é a sua idade?</strong>
        <div className='resposta'>R.: {idade} anos.</div>
      </div>
      <div className='opcoes-de-respostas'>
        <div>{idade && idade >= 60 && idade <= 74 ? '(x)' : <>(&nbsp;&nbsp;)</>} 60 a 74 anos⁰</div>
        <div>{idade && idade >= 75 && idade <= 84 ? '(x)' : <>(&nbsp;&nbsp;)</>} 75 a 84 anos¹</div>
        <div>{idade && idade >= 85 ? '(x)' : <>(&nbsp;&nbsp;)</>} ≥ 85 anos³</div>
      </div>
      <div className='pontuacao'>{(resultado || resultado === 0) ? `${resultado}` : ''}</div>
    </r-cell>
  )
}

function AutoPercepcaoDaSaude({ valor = {} }) {
  const { nivelDeSaudeRelativa, resultado } = valor

  return (
    <r-cell span={4} span-md={6} span-lg={12} class='auto-percepcao-da-saude leitura'>
      <div className='agrupamento'><strong>AUTO-PERCEPÇÃO DA SAÚDE</strong></div>
      <div className='pergunta'>
        <strong>2. Em geral, comparando com outras pessoas da sua idade, você diria que sua saúde é:</strong>
        <div className='resposta'>R.: {nivelDeSaudeRelativa}</div>
      </div>
      <div className='opcoes-de-respostas'>
        <div>{nivelDeSaudeRelativa.toUpperCase() === 'EXCELENTE' || nivelDeSaudeRelativa.toUpperCase() === 'MUITO BOA' || nivelDeSaudeRelativa.toUpperCase() === 'BOA' ? '(x)' : <>(&nbsp;&nbsp;)</>} Excelente, muito boa ou boa⁰</div>
        <div>{nivelDeSaudeRelativa.toUpperCase() === 'REGULAR' || nivelDeSaudeRelativa.toUpperCase() === 'RUIM' ? '(x)' : <>(&nbsp;&nbsp;)</>} Regular ou ruim¹</div>
      </div>
      <div className='pontuacao'>{(resultado || resultado === 0) ? `${resultado}` : ''}</div>
    </r-cell>
  )
}

function AtividadesDeVidaDiaria({ valor = {} }) {
  const { avdInstrumental, avdBasica } = valor

  return (
    <r-cell span={4} span-md={6} span-lg={12} class='atividades-de-vida-diaria'>
      <div className='titulo-vertical'>
        <div><strong>ATIVIDADES DE</strong></div>
        <div><strong>VIDA DIÁRIA</strong></div>
      </div>
      <div className='itens'>
        <AvdInstrumental pontuacao={4} valor={avdInstrumental} />
        <AvdBasica pontuacao={6} valor={avdBasica} />
      </div>
    </r-cell>
  )
}

function AvdInstrumental({ valor = {} }) {
  const { deixouDeFazerCompras, deixouDeControlarSuasFinancas, deixouDeRealizarPequenosTrabalhosDomesticos, resultado } = valor

  return (
    <div className='avd-instrumental'>
      <div className='agrupamento'>
        <div className='titulo-avd-instrumental'>AVD Instrumental</div>
        <div className='detalhes'>Resposta positiva vale 4 pontos cada. Todavia, a pontuação máxima do item é de 4 pontos, mesmo que o idoso tenha respondido sim para todas as questões 3, 4 e 5. </div>
      </div>
      <div className='pergunta'>
        <MultiplaEscolha
          className='deixou-de-fazer-compras'
          nome='atividadesDeVidaDiaria.avdInstrumental.deixouDeFazerCompras'
          opcoes={[{ codigo: true, descricao: 'Sim⁴' }, { codigo: false, descricao: 'Não ou não faz compras por outros motivos que não a saúde' }]}
          pergunta='3. Por causa de sua saúde ou condição física, você deixou de fazer compras?'
          valor={deixouDeFazerCompras}
          leitura={true}
          renderizarTitulo={renderizarTitulo}
        />
        <MultiplaEscolha
          className='deixou-de-controlar-suas-financas'
          nome='atividadesDeVidaDiaria.avdInstrumental.deixouDeControlarSuasFinancas'
          opcoes={[{ codigo: true, descricao: 'Sim⁴' }, { codigo: false, descricao: 'Não ou não controla o dinheiro por outros motivos que não a saúde' }]}
          pergunta='4. Por causa de sua saúde ou condição física, você deixou de controlar seu dinheiro, gastos ou pagar as contas de sua casa?'
          valor={deixouDeControlarSuasFinancas}
          leitura={true}
          renderizarTitulo={renderizarTitulo}
        />
        <MultiplaEscolha
          className='deixou-de-realizar-pequenos-trabalhos-domesticos'
          nome='atividadesDeVidaDiaria.avdInstrumental.deixouDeRealizarPequenosTrabalhosDomesticos'
          opcoes={[{ codigo: true, descricao: 'Sim⁴' }, { codigo: false, descricao: 'Não ou não faz mais pequenos trabalhos domésticos por outros motivos que não a saúde' }]}
          pergunta='5. Por causa de sua saúde ou condição física, você deixou de realizar pequenos trabalhos domésticos, como lavar louça, arrumar a casa ou fazer limpeza leve?'
          valor={deixouDeRealizarPequenosTrabalhosDomesticos}
          leitura={true}
          renderizarTitulo={renderizarTitulo}
        />
      </div>
      <div className='pontuacao'>{(resultado || resultado === 0) ? resultado : 'Máximo 4 pts'}</div>
    </div>
  )
}

function AvdBasica({ valor = {} }) {
  const { deixouDeTomarBanhoSozinho, resultado } = valor

  return (
    <div className='avd-basica'>
      <div className='agrupamento'>
        AVD Básica
      </div>
      <div className='pergunta'>
        <MultiplaEscolha
          className='deixou-de-tomar-banho-sozinho'
          nome='atividadesDeVidaDiaria.avdBasica.deixouDeTomarBanhoSozinho'
          opcoes={[{ codigo: true, descricao: 'Sim⁶' }, { codigo: false, descricao: 'Não' }]}
          pergunta='6. Por causa de sua saúde ou condição física, você deixou de tomar banho sozinho?'
          valor={deixouDeTomarBanhoSozinho}
          leitura={true}
          renderizarTitulo={renderizarTitulo}
        />
      </div>
      <div className='pontuacao'>{(resultado || resultado === 0) ? resultado : ''}</div>
    </div>
  )
}

function Cognicao({ valor = {} }) {
  const { estahFicandoEsquecido, esquecimentoEstahPiorando, esquecimentoImpedeRealizacaoDeAtividadesDoCotidiano, resultado } = valor

  return (
    <r-cell span={4} span-md={6} span-lg={12} class='cognicao'>
      <div className='agrupamento'><strong>COGNIÇÃO</strong></div>
      <div className='pergunta'>
        <MultiplaEscolha
          className='estah-ficando-esquecido'
          nome='cognicao.estahFicandoEsquecido'
          opcoes={[{ codigo: true, descricao: 'Sim¹' }, { codigo: false, descricao: 'Não' }]}
          pergunta='7. Algum familiar ou amigo falou que você está ficando esquecido?'
          valor={estahFicandoEsquecido}
          leitura={true}
          renderizarTitulo={renderizarTitulo}
        />
        <MultiplaEscolha
          className='esquecimento-estah-piorando'
          nome='cognicao.esquecimentoEstahPiorando'
          opcoes={[{ codigo: true, descricao: 'Sim¹' }, { codigo: false, descricao: 'Não' }]}
          pergunta='8. Este esquecimento está piorando nos últimos meses?'
          valor={esquecimentoEstahPiorando}
          leitura={true}
          renderizarTitulo={renderizarTitulo}
        />
        <MultiplaEscolha
          className='esquecimento-impede-realizacao-de-atividades-do-cotidiano'
          nome='cognicao.esquecimentoImpedeRealizacaoDeAtividadesDoCotidiano'
          opcoes={[{ codigo: true, descricao: 'Sim²' }, { codigo: false, descricao: 'Não' }]}
          pergunta='9. Este esquecimento está impedindo a realização de alguma atividade do cotidiano?'
          valor={esquecimentoImpedeRealizacaoDeAtividadesDoCotidiano}
          leitura={true}
          renderizarTitulo={renderizarTitulo}
        />
      </div>
      <div className='pontuacao'>{(resultado || resultado === 0) ? resultado : ''}</div>
    </r-cell>
  )
}

function Humor({ valor = {} }) {
  const { ficouComDesanimoTristezaOuDesesperanca, perdeuInteresseEmAtividadesPrazerosas, resultado } = valor

  return (
    <r-cell span={4} span-md={6} span-lg={12} class='humor'>
      <div className='agrupamento'><strong>HUMOR</strong></div>
      <div className='pergunta'>
        <MultiplaEscolha
          className='estah-com-desanimo-tristeza-ou-desesperanca'
          nome='humor.ficouComDesanimoTristezaOuDesesperanca'
          opcoes={[{ codigo: true, descricao: 'Sim²' }, { codigo: false, descricao: 'Não' }]}
          pergunta='10. No último mês, você ficou com desânimo, tristeza ou desesperança?'
          valor={ficouComDesanimoTristezaOuDesesperanca}
          leitura={true}
          renderizarTitulo={renderizarTitulo}
        />
        <MultiplaEscolha
          className='perdeu-interesse-em-atividades-prazerosas'
          nome='humor.perdeuInteresseEmAtividadesPrazerosas'
          opcoes={[{ codigo: true, descricao: 'Sim²' }, { codigo: false, descricao: 'Não' }]}
          pergunta='11. No último mês, você perdeu o interesse ou prazer em atividades anteriormente prazerosas?'
          valor={perdeuInteresseEmAtividadesPrazerosas}
          leitura={true}
          renderizarTitulo={renderizarTitulo}
        />
      </div>
      <div className='pontuacao'>{(resultado || resultado === 0) ? resultado : ''}</div>
    </r-cell>
  )
}

function Mobilidade({ valor = [] }) {
  const {
    alcancePreensaoEPinca,
    capacidadeAerobicaEOuMuscular,
    marcha,
    continenciaEsfincteriana,
  } = valor

  return (
    <r-cell span={4} span-md={6} span-lg={12} class='mobilidade'>
      <div className='titulo-vertical'>
        <div><strong>MOBILIDADE</strong></div>
      </div>
      <div className='itens'>
        <AlcancePreensaoEPinca valor={alcancePreensaoEPinca} />
        <CapacidadeAerobicaEOuMuscular valor={capacidadeAerobicaEOuMuscular} />
        <Marcha valor={marcha} />
        <ContinenciaEsfincteriana valor={continenciaEsfincteriana} />
      </div>
    </r-cell>
  )
}

function AlcancePreensaoEPinca({ valor = {} }) {
  const { ehIncapazDeElevarOsBracosAcimaDoOmbro, ehIncapazDeManusearPequenosObjetos, resultado } = valor

  return (
    <div className='alcance-preensao-e-pinca'>
      <div className='agrupamento'>Alcance, preensão e pinça</div>
      <div className='pergunta'>
        <MultiplaEscolha
          className='eh-incapaz-de-elevar-os-bracos-acima-do-ombro'
          nome='mobilidade.alcancePreensaoEPinca.ehIncapazDeElevarOsBracosAcimaDoOmbro'
          opcoes={[{ codigo: true, descricao: 'Sim¹' }, { codigo: false, descricao: 'Não' }]}
          pergunta='12. Você é incapaz de elevar os braços acima do nível do ombro?'
          valor={ehIncapazDeElevarOsBracosAcimaDoOmbro}
          leitura={true}
          renderizarTitulo={renderizarTitulo}
        />
        <MultiplaEscolha
          className='eh-incapaz-de-manusear-pequenos-objetos'
          nome='mobilidade.alcancePreensaoEPinca.ehIncapazDeManusearPequenosObjetos'
          opcoes={[{ codigo: true, descricao: 'Sim¹' }, { codigo: false, descricao: 'Não' }]}
          pergunta='13. Você é incapaz de manusear ou segurar pequenos objetos?'
          valor={ehIncapazDeManusearPequenosObjetos}
          leitura={true}
          renderizarTitulo={renderizarTitulo}
        />
      </div>
      <div className='pontuacao'>{(resultado || resultado === 0) ? resultado : ''}</div>
    </div>
  )
}

function CapacidadeAerobicaEOuMuscular({ valor = {} }) {
  const { altura, circunferenciaDaPanturrilha, tevePerdaDePesoNaoIntencional, tempoGastoParaPercorrerQuatroMetros, peso, imc, resultado } = valor
  const algumItemSelecionado = tevePerdaDePesoNaoIntencional || imc < 22 || circunferenciaDaPanturrilha < 31 || tempoGastoParaPercorrerQuatroMetros > 5

  return (
    <div className='capacidade-aerobica-e-ou-muscular leitura'>
      <div className='agrupamento'>Capacidade aeróbica e/ou muscular</div>
      <div className='pergunta'>
        <div className='texto-da-pergunta'>
          <strong>14. Você tem alguma das quatro condições abaixo relacionadas?</strong>
        </div>
        <div className='condicoes'>
          <TevePercaDePesoNaoIntencional tevePerdaDePesoNaoIntencional={tevePerdaDePesoNaoIntencional} />
          <IndiceDeMassaCorporal altura={altura} peso={peso} imc={imc} />
          <CircunferenciaDaPanturrilha circunferenciaDaPanturrilha={circunferenciaDaPanturrilha} />
          <TempoGastoParaPercorrerQuatroMetros tempoGastoParaPercorrerQuatroMetros={tempoGastoParaPercorrerQuatroMetros} />
        </div>
        <div className='respostas'>
          <div>({algumItemSelecionado ? 'x' : ' '}) Sim²</div>
          <div>({!algumItemSelecionado ? 'x' : ' '}) Não</div>
        </div>
      </div>
      <div className='pontuacao'>{(resultado || resultado === 0) ? resultado : 'Máximo 2 pts'}</div>
    </div>
  )
}

function TevePercaDePesoNaoIntencional({ tevePerdaDePesoNaoIntencional }) {
  return (
    <div className='teve-perca-de-peso-nao-intencional'>
      - Perda de peso não intencional de 4,5 kg ou 5% do peso corporal no último ano <span className='negrito'>ou</span> 6 kg nos últimos 6 meses <span className='negrito'>ou</span> 3 kg no último mês ({tevePerdaDePesoNaoIntencional ? 'x' : ' '});
    </div>
  )
}

function IndiceDeMassaCorporal({ altura, imc, peso }) {
  return (
    <div className='indice-de-massa-corporal'>
      <div className='visualizacao-imc'>
        <span>- Índice de Massa Corporal (IMC) menor que 22 kg/m ({imc && imc < 22 ? 'x' : ' '});</span>
        <span>R.: Peso: {peso && peso.toString().replace('.', ',')} kg. Altura: {altura && altura.toString().replace('.', ',')} cm. IMC: {imc && imc.toString().replace('.', ',')} kg/m².</span>
      </div>
    </div>
  )
}

function CircunferenciaDaPanturrilha({ circunferenciaDaPanturrilha }) {
  return (
    <div className='circunferencia-da-panturrilha'>
      <div className='visualizacao-imc'>
        <span>- Circunferência da panturrilha {'<'} 31 cm ({circunferenciaDaPanturrilha && circunferenciaDaPanturrilha < 31 ? 'x' : ' '});</span>
        <span>R.: {circunferenciaDaPanturrilha && circunferenciaDaPanturrilha.toString().replace('.', ',')} cm.</span>
      </div>
    </div>
  )
}

function TempoGastoParaPercorrerQuatroMetros({ tempoGastoParaPercorrerQuatroMetros }) {
  return (
    <div className='tempo-gasto-para-percorrer-quatro-metros'>
      <div className='visualizacao-imc'>
        <span>- Tempo gasto no teste de velocidade da marcha (4 metros) {'>'} 5 segundos ({tempoGastoParaPercorrerQuatroMetros && tempoGastoParaPercorrerQuatroMetros > 5 ? 'x' : ' '});</span>
        <span>R.: {tempoGastoParaPercorrerQuatroMetros.toString().replace('.', ',')} segundos.</span>
      </div>
    </div>
  )
}

function Marcha({ valor = {} }) {
  const { temDificuldadeParaCaminhar, teveQuedasRecentes, resultado } = valor

  return (
    <div className='marcha'>
      <div className='agrupamento'>Marcha</div>
      <div className='pergunta'>
        <MultiplaEscolha
          className='tem-dificuldade-para-caminhar'
          nome='mobilidade.marcha.temDificuldadeParaCaminhar'
          opcoes={[{ codigo: true, descricao: 'Sim²' }, { codigo: false, descricao: 'Não' }]}
          pergunta='15. Você tem dificuldade para caminhar capaz de impedir a realização de alguma atividade do cotidiano?'
          valor={temDificuldadeParaCaminhar}
          leitura={true}
          renderizarTitulo={renderizarTitulo}
        />
        <MultiplaEscolha
          className='teve-quedas-recente'
          nome='mobilidade.marcha.teveQuedasRecentes'
          opcoes={[{ codigo: true, descricao: 'Sim²' }, { codigo: false, descricao: 'Não' }]}
          pergunta='16. Você teve duas ou mais quedas no último ano?'
          valor={teveQuedasRecentes}
          leitura={true}
          renderizarTitulo={renderizarTitulo}
        />
      </div>
      <div className='pontuacao'>{(resultado || resultado === 0) ? resultado : ''}</div>
    </div>
  )
}

function ContinenciaEsfincteriana({ valor = {} }) {
  const { perdeUrinaOuFezesSemQuerer, resultado } = valor

  return (
    <div className='continencia-esfincteriana'>
      <div className='agrupamento'>Continência esfincteriana</div>
      <div className='pergunta'>
        <MultiplaEscolha
          className='perdeu-urina-ou-fezes-sem-querer'
          nome='mobilidade.continenciaEsfincteriana.perdeUrinaOuFezesSemQuerer'
          opcoes={[{ codigo: true, descricao: 'Sim²' }, { codigo: false, descricao: 'Não' }]}
          pergunta='17. Você perde urina ou fezes, sem querer, em algum momento?'
          valor={perdeUrinaOuFezesSemQuerer}
          leitura={true}
          renderizarTitulo={renderizarTitulo}
        />
      </div>
      <div className='pontuacao'>{(resultado || resultado === 0) ? resultado : ''}</div>
    </div>
  )
}

function Comunicacao({ valor = {} }) {
  const { audicao, visao } = valor

  return (
    <r-cell span={4} span-md={6} span-lg={12} class='comunicacao'>
      <div className='titulo-vertical'>
        <div><strong>COMUNICAÇÃO</strong></div>
      </div>
      <div className='itens'>
        <Visao valor={visao} />
        <Audicao valor={audicao} />
      </div>
    </r-cell>
  )
}

function Visao({ valor = {} }) {
  const { temProblemasDeVisao, resultado } = valor

  return (
    <div className='visao'>
      <div className='agrupamento'>Visão</div>
      <div className='pergunta'>
        <MultiplaEscolha
          className='tem-problemas-de-visao'
          nome='comunicacao.visao.temProblemasDeVisao'
          opcoes={[{ codigo: true, descricao: 'Sim²' }, { codigo: false, descricao: 'Não' }]}
          pergunta='18. Você tem problemas de visão capazes de impedir a realização de alguma atividade do cotidiano? É permitido o uso de óculos ou lentes de contato'
          valor={temProblemasDeVisao}
          leitura={true}
          renderizarTitulo={renderizarTitulo}
        />
      </div>
      <div className='pontuacao'>{(resultado || resultado === 0) ? resultado : ''}</div>
    </div>
  )
}

function Audicao({ valor = {} }) {
  const { temProblemasDeAudicao, resultado } = valor

  return (
    <div className='audicao'>
      <div className='agrupamento'>Audição</div>
      <div className='pergunta'>
        <MultiplaEscolha
          className='tem-problemas-de-audicao'
          nome='comunicacao.audicao.temProblemasDeAudicao'
          opcoes={[{ codigo: true, descricao: 'Sim²' }, { codigo: false, descricao: 'Não' }]}
          pergunta='19. Você tem problemas de audição capazes de impedir a realização de alguma atividade do cotidiano? É permitido o uso de aparelhos de audição.'
          valor={temProblemasDeAudicao}
          leitura={true}
          renderizarTitulo={renderizarTitulo}
        />
      </div>
      <div className='pontuacao'>{(resultado || resultado === 0) ? resultado : ''}</div>
    </div>
  )
}

function ComorbidadesMultiplas({ valor = {} }) {
  const { polifarmacia = {}, polipatologia = {}, internacaoRecente = {}, resultado } = valor
  const sim = internacaoRecente.teveInternacaoRecente || polipatologia.temCincoOuMaisDoencasCronicas || polifarmacia.usaCincoOuMaisMedicamentos

  return (
    <r-cell span={4} span-md={6} span-lg={12} class='comorbidades-multiplas'>
      <div className='titulo-vertical'>
        <div><strong>COMORBIDADES</strong></div>
        <div><strong>MÚLTIPLAS</strong></div>
      </div>
      <div className='alguma-das-tres-condicoes-relacionadas'>
        <div className='agrupamento'>
          <div>Polipatologia</div>
          <div>Polifarmácia</div>
          <div>Internação recente<br />{'(<'}6 meses)</div>
        </div>
        <div className='pergunta leitura'>
          <span>- Cinco ou mais doenças crônicas ({polipatologia.temCincoOuMaisDoencasCronicas ? 'x' : ' '});</span>
          <span>- Uso regular de cinco ou mais medicamentos diferentes, todo dia ({polifarmacia.usaCincoOuMaisMedicamentos ? 'x' : ' '});</span>
          <span>- Internação recente, nos últimos 6 meses ({internacaoRecente.teveInternacaoRecente ? 'x' : ' '}).</span>
          <div className='respostas'>
            <div>({sim ? 'x' : ' '}) Sim⁴ </div>
            <div>({!sim ? 'x' : ' '} ) Não</div>
          </div>
        </div>
        <div className='pontuacao'>{(resultado || resultado === 0) ? resultado : <span>Máximo 4 pts</span>}</div>
      </div>
    </r-cell>
  )
}

function CabecalhoComplementar() {
  return (
    <r-cell span={4} span-md={6} span-lg={12} class='cabecalho'>
      <div className='titulo-ivcf'>
        <strong>INFORMAÇÕES COMPLEMENTARES AO IVCF</strong>
      </div>
    </r-cell>
  )
}

function Moradia({ valor = {} }) {
  const { moraSozinho } = valor

  return (
    <r-cell span={4} span-md={6} span-lg={12} class='moradia'>
      <div className='agrupamento'><strong>MORADIA</strong></div>
      <div className='pergunta'>
        <MultiplaEscolha
          className='mora-sozinho'
          nome='moradia.moraSozinho'
          opcoes={[{ codigo: true, descricao: 'Sim' }, { codigo: false, descricao: 'Não' }]}
          pergunta='1. Você mora sozinho?'
          valor={moraSozinho}
          leitura={true}
          renderizarTitulo={renderizarTitulo}
        />
      </div>
    </r-cell>
  )
}
import atendimento from './atendimento'
import tiposDeAtendimento from './tipos-de-atendimento'
import zarit from './zarit'
import casp16 from './casp-16'
import escalaDaSolidao from './escala-da-solidao'
import memed from './memed'
import lacuna from './lacuna'
import teleconsulta from './teleconsulta'

const estadoInicial = {
  pacientes: {},
  tiposDeAtendimento: {},
  casp16: [],
  escalaDaSolidao: [],
  zarit: {
    frequencias: [],
    avaliacoes: []
  },
  memed: {},
  lacuna: {},
  teleconsulta: false
}

export default (state = estadoInicial, action) => {
  return {
    ...state,
    escalaDaSolidao: escalaDaSolidao(state.escalaDaSolidao, action),
    casp16: casp16(state.casp16, action),
    zarit: zarit(state.zarit, action),
    pacientes: atendimento(state.pacientes, action),
    tiposDeAtendimento: tiposDeAtendimento(state.tiposDeAtendimento, action),
    memed: memed(state.memed, action),
    lacuna: lacuna(state.lacuna, action),
    teleconsulta: teleconsulta(state.teleconsulta, action)
  }
}
import moment from 'moment'
import {
  IDENTIFICAR_PACIENTE_SEM_PROGRAMA_NO_AGENDAMENTO,
  ADICIONAR_PROXIMOS_AGENDAMENTOS_DO_AGENDAMENTO,
  ADICIONOU_PRE_CADASTRO_DO_PACIENTE,
  ALTERAR_DADOS_SELECIONADOS_DO_AGENDAMENTO,
  ALTEROU_DADOS_SELECIONADOS_DO_AGENDAMENTO_NA_LISTA_DE_ESPERA,
  ERRO_AO_LISTAR_HORARIOS_LIVRES,
  LIMPAR_ALERTAS_DO_AGENDAMENTO,
  LIMPAR_AGENDAMENTO_ATUAL,
  LIMPAR_DADOS_DO_PACIENTE_DO_AGENDAMENTO,
  LIMPAR_HORARIOS_LIVRES,
  LISTOU_HORARIOS_LIVRES,
  LISTOU_PACIENTES_DO_AGENDAMENTO,
  LISTOU_TIPOS_DE_AGENDAMENTO_DO_AGENDAMENTO,
  RECUPEROU_TELECONSULTA_DO_AGENDAMENTO,
  RECUPEROU_TELECONSULTA_DO_ATENDIMENTO,
  ERRO_AO_RECUPERAR_TELECONSULTA_DO_AGENDAMENTO,
  RECUPEROU_AGENDAMENTO_PELO_IDENTIFICADOR,
  RECUPEROU_CONVENIOS_DO_PACIENTE_SELECIONADO_DO_AGENDAMENTO,
  RECUPEROU_PROGRAMAS_DO_PACIENTE_SELECIONADO_DO_AGENDAMENTO,
  SELECIONAR_AGENDAMENTO_PARA_ALTERACAO,
  SELECIONAR_PACIENTE_DO_AGENDAMENTO,
  ERRO_AO_RECUPERAR_TELECONSULTA_DO_ATENDIMENTO,
} from "../../acoes/tipos"

const estadoInicial = {
  data: null,
  dataBaseHorariosLivres: null,
  duracaoDoAgendamento: null,
  horariosLivres: [],
  paciente: {
    convenios: {},
    emEspera: false,
    identificador: null,
    programas: {},
  },
  pacientes: {},
  selecionado: null,
  tiposDeAgendamento: [],
  unidade: null,
  agendamento: '',
  semPrograma: null,
  proximosAgendamentos: null,
  tipoDeHorario: '',
  teleconsulta: {},
}

export default (state = estadoInicial, action) => {
  switch (action.type) {
    case LISTOU_PACIENTES_DO_AGENDAMENTO: {
      let itens = {}

      action.pacientes.dados.forEach((paciente, index) => {
        itens[paciente.identificador] = {
          ...paciente,
          medicoDeReferencia: {
            ...paciente.medicoDeReferencia,
            nome: paciente.medicoDeReferencia?.tratamento ? paciente.medicoDeReferencia.tratamento + ' ' + paciente.medicoDeReferencia.nome : paciente.medicoDeReferencia?.nome,
          },
          enfermeiroDeReferencia: {
            ...paciente.enfermeiroDeReferencia,
            nome: paciente.enfermeiroDeReferencia?.tratamento ? paciente.enfermeiroDeReferencia.tratamento + ' ' + paciente.enfermeiroDeReferencia.nome : paciente.enfermeiroDeReferencia?.nome,
          },
          _ordem: index,
        }
      })

      let paginacao = {
        ...action.pagina,
      }

      if (paginacao['dados']) {
        delete paginacao['dados']
      }

      return {
        ...state,
        pacientes: itens,
      }
    }

    case SELECIONAR_PACIENTE_DO_AGENDAMENTO: {
      return {
        ...state,
        paciente: {
          ...estadoInicial.paciente,
          identificador: action.paciente.identificador,
        },
        proximosAgendamentos: null,
        semPrograma: null
      }
    }

    case LIMPAR_ALERTAS_DO_AGENDAMENTO: {
      return {
        ...state,
        semPrograma: null,
        proximosAgendamentos: null
      }
    }

    case LIMPAR_DADOS_DO_PACIENTE_DO_AGENDAMENTO: {
      return {
        ...state,
        paciente: {
          ...estadoInicial.paciente
        },
        proximosAgendamentos: null,
        semPrograma: null
      }
    }

    case LIMPAR_AGENDAMENTO_ATUAL: {
      return estadoInicial
    }

    case RECUPEROU_CONVENIOS_DO_PACIENTE_SELECIONADO_DO_AGENDAMENTO: {
      let convenios = {}

      action.conveniosDoPacienteSelecionadoDoAgendamento.forEach(convenio => {
        convenios[convenio.identificador] = convenio
      })

      return {
        ...state,
        paciente: {
          ...state.paciente,
          convenios,
        },
      }
    }

    case RECUPEROU_PROGRAMAS_DO_PACIENTE_SELECIONADO_DO_AGENDAMENTO: {
      let programas = {}

      action.programasDoPacienteSelecionadoDoAgendamento.forEach(programa => {
        programas[programa.identificador] = programa
      })

      return {
        ...state,
        paciente: {
          ...state.paciente,
          programas,
        },
      }
    }

    case LISTOU_TIPOS_DE_AGENDAMENTO_DO_AGENDAMENTO: {
      return {
        ...state,
        tiposDeAgendamento: action.tiposDeAgendamento,
      }
    }

    case LISTOU_HORARIOS_LIVRES: {
      let horariosLivres = { ...state.horariosLivres }

      const horarios = [...action.horariosLivres]
      const agora = moment()

      horarios.forEach(horario => {
        const chave = moment(horario.inicio).format('YYYY-MM-DD')

        if (moment(horario.inicio).isBefore(agora, 'minutes')) {
          return
        }

        if (!horariosLivres[chave]) {
          horariosLivres[chave] = {
            data: chave,
            unidades: {}
          }
        }

        const unidade = horariosLivres[chave].unidades[horario.unidade.identificador]
        if (unidade) {
          unidade.horarios.forEach(x => {
            delete x.profissional
            delete x.unidade
          })
        }

        horariosLivres[chave] = {
          data: chave,
          unidades: {
            ...horariosLivres[chave].unidades,
            [horario.unidade.identificador]: {
              ...horario.unidade,
              horarios: unidade && unidade.horarios ?
                [...unidade.horarios, horario] :
                [horario],
            }
          }
        }
      })

      return {
        ...state,
        dataBaseHorariosLivres: action.dataBaseHorariosLivres,
        horariosLivres,
      }
    }

    case LIMPAR_HORARIOS_LIVRES: {
      return {
        ...state,
        dataBaseHorariosLivres: null,
        horariosLivres: [],
      }
    }

    case ADICIONOU_PRE_CADASTRO_DO_PACIENTE: {
      let pacientes = state.pacientes

      pacientes[action.preCadastro.identificador] = {
        ...action.preCadastro,
      }

      return {
        ...state,
        paciente: {
          convenios: {},
          programas: {},
          identificador: action.preCadastro.identificador,
        },
        pacientes,
        proximosAgendamentos: null,
        semPrograma: null
      }
    }

    case SELECIONAR_AGENDAMENTO_PARA_ALTERACAO: {
      return {
        ...state,
        paciente: {
          ...estadoInicial.paciente,
          identificador: action.agendamento.paciente.identificador,
        },
        selecionado: action.agendamento,
      }
    }

    case ALTERAR_DADOS_SELECIONADOS_DO_AGENDAMENTO: {
      return {
        ...state,
        data: action.dadosSelecionadosDoAgendamento.data,
        duracaoDoAgendamento: action.dadosSelecionadosDoAgendamento.duracaoDoAgendamento,
        unidade: action.dadosSelecionadosDoAgendamento.unidade,
        tipoDeHorario: action.dadosSelecionadosDoAgendamento.tipoDeHorario,
      }
    }

    case ALTEROU_DADOS_SELECIONADOS_DO_AGENDAMENTO_NA_LISTA_DE_ESPERA: {
      let pacientes = state.pacientes

      pacientes[action.pacienteSelecionado.identificador] = {
        ...action.pacienteSelecionado,
      }

      return {
        ...state,
        paciente: {
          ...state.paciente,
          identificador: action.pacienteSelecionado.identificador,
          convenios: action.pacienteSelecionado.convenios,
          emEspera: true,
        },
        pacientes,
      }
    }

    case RECUPEROU_AGENDAMENTO_PELO_IDENTIFICADOR: {
      return {
        ...state,
        agendamento: action.agendamento
      }
    }

    case ADICIONAR_PROXIMOS_AGENDAMENTOS_DO_AGENDAMENTO: {
      return {
        ...state,
        proximosAgendamentos: action.proximos
      }
    }

    case IDENTIFICAR_PACIENTE_SEM_PROGRAMA_NO_AGENDAMENTO: {
      return {
        ...state,
        semPrograma: action.semPrograma
      }
    }

    case RECUPEROU_TELECONSULTA_DO_ATENDIMENTO:
    case RECUPEROU_TELECONSULTA_DO_AGENDAMENTO: {
      return {
        ...state,
        teleconsulta: action.teleconsulta
      }
    }

    case ERRO_AO_RECUPERAR_TELECONSULTA_DO_ATENDIMENTO:
    case ERRO_AO_RECUPERAR_TELECONSULTA_DO_AGENDAMENTO: {
      return {
        ...state,
        teleconsulta: {}
      }
    }

    case ERRO_AO_LISTAR_HORARIOS_LIVRES: {
      return {
        ...state,
        horariosLivres: [],
      }
    }

    default: {
      return state
    }
  }
}
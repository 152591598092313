import React from 'react'
import {Aba, Abas, ListaDeAbas, Paineis, Painel} from '../atendimento/abas'
import {usePossuiAlgumaPermissao, usePossuiAsPermissoes} from '../../seguranca/permissao'
import * as permissoes from '../../seguranca/permissoes'
import Diagnosticos from './diagnosticos/'
import Medicamentos from './medicamentos/'
import IdasAoProntoAtendimento from './idas-ao-pronto-atendimento/'
import Internacoes from './internacoes/'
import Vacinas from './vacinas/'
import Quedas from './quedas/'
import InfeccoesDoTratoUrinario from './infeccoes-do-trato-urinario/'
import TimelineDoPaciente from './timeline-do-paciente/'
import Questionarios from './questionarios/'
import Exames from './exames/'
import ConsultasExternas from './consultas-externas'
import RegistrosDocumentaisDoPsicologo from './registros-documentais-do-psicologo'
import Triagens from './triagens'

export default function GestaoDeCondicoesClinicas(props) {
  const {
    identificador,
    medicamentos,
    diagnosticos,
    cabecalho,
    gestaoDeCondicoesClinicas,
    historicoDeCampos,
  } = props

  const permissoesQuestionarios = [
    permissoes.LISTAR_IVCFS_DO_PACIENTE,
    permissoes.LISTAR_AVALIACOES_DA_SOBRECARGA_DO_CUIDADOR_DO_PACIENTE,
    permissoes.LISTAR_CASPS_16_DO_PACIENTE,
    permissoes.LISTAR_AVALIACOES_DA_SOLIDAO_DO_PACIENTE
  ]

  const podeListarQuestionarios = usePossuiAlgumaPermissao(permissoesQuestionarios)
  const podeListarVacinas = usePossuiAsPermissoes([permissoes.LISTAR_VACINAS_DO_PACIENTE])
  const podeListarQuedas = usePossuiAsPermissoes([permissoes.LISTAR_QUEDAS_DO_PACIENTE])
  const podeListarInfeccoes = usePossuiAsPermissoes([permissoes.LISTAR_INFECCOES_DO_TRATO_URINARIO_DO_PACIENTE])
  const podeListarIdasAoProntoAtendimento = usePossuiAsPermissoes([permissoes.LISTAR_IDAS_AO_PRONTO_ATENDIMENTO_DO_PACIENTE])
  const podeListarInternacoes = usePossuiAsPermissoes([permissoes.LISTAR_INTERNACOES_DO_PACIENTE])
  const podeListarConsultasExternas = usePossuiAlgumaPermissao([permissoes.LISTAR_CONSULTAS_EXTERNAS_DO_PACIENTE])
  const podeListarRegistrosDocumentaisDoPsicologo = usePossuiAlgumaPermissao([permissoes.LISTAR_REGISTROS_DOCUMENTAIS_DO_PSICOLOGO_DO_PACIENTE])
  const podeListarTriagens = usePossuiAlgumaPermissao([permissoes.LISTAR_TRIAGENS])

  const paciente = {
    ...cabecalho.identificacao,
    identificador: identificador,
  }

  return (
    <div className='form mt-2 gestao-de-condicoes-clinicas'>
      <fieldset>
        <Abas>
          <r-grid columns-md={6} columns-lg={12} class='align-items-center'>
            <r-cell span={4} span-md={6} span-lg={12}>
              <ListaDeAbas className='tab'>
                <Aba>Diagnósticos/Medicamentos</Aba>
                {podeListarIdasAoProntoAtendimento ? <Aba title='Idas ao Pronto Atendimento'>PA</Aba> : null}
                {podeListarInternacoes ? <Aba>Internações</Aba> : null}
                {podeListarTriagens ? <Aba>Triagens</Aba> : null}
                {podeListarVacinas ? <Aba>Vacinas</Aba> : null}
                {podeListarQuedas ? <Aba>Quedas</Aba> : null}
                {podeListarInfeccoes ? <Aba title='Infecções do Trato Urinário'>ITU's</Aba> : null}
                {podeListarConsultasExternas ? <Aba>Consultas externas</Aba> : null}
                {podeListarRegistrosDocumentaisDoPsicologo ? <Aba title='Registros documentais do psicólogo'>Registros documentais</Aba> : null}
                {podeListarQuestionarios ? <Aba>Questionários</Aba> : null}
                <Aba>Exames</Aba>
                <Aba>Timeline</Aba>
              </ListaDeAbas>
            </r-cell>
          </r-grid>
          <Paineis>
            <Painel>
              <r-grid columns-md={6} columns-lg={12}>
                <Diagnosticos
                  diagnosticosDoPaciente={gestaoDeCondicoesClinicas.diagnosticos}
                  diagnosticos={diagnosticos}
                  identificadorDoPaciente={identificador}
                />
                <Medicamentos
                  medicamentosDoPaciente={gestaoDeCondicoesClinicas.medicamentos}
                  medicamentos={medicamentos.medicamentos}
                  posologias={medicamentos.posologias}
                  identificadorDoPaciente={identificador}
                />
              </r-grid>
            </Painel>
            <Painel key='idasAoPa'><IdasAoProntoAtendimento identificadorDoPaciente={identificador} idasAoProntoAtendimentoDoPaciente={gestaoDeCondicoesClinicas.idasAoProntoAtendimento} /></Painel>
            <Painel key='internacoes'><Internacoes identificadorDoPaciente={identificador} internacoesDoPaciente={gestaoDeCondicoesClinicas.internacoes} /></Painel>
            <Painel key='triagens'><Triagens triagens={gestaoDeCondicoesClinicas.triagens} paciente={paciente}/></Painel>
            <Painel key='vacinas'><Vacinas identificadorDoPaciente={identificador} vacinasDoPaciente={gestaoDeCondicoesClinicas.vacinas} /></Painel>
            <Painel key='quedas'><Quedas identificadorDoPaciente={identificador} quedasDoPaciente={gestaoDeCondicoesClinicas.quedas}/></Painel>
            <Painel key='itu'><InfeccoesDoTratoUrinario identificadorDoPaciente={identificador} infeccoesDoPaciente={gestaoDeCondicoesClinicas.infeccoesDoTratoUrinario} /></Painel>
            <Painel key='consultasExternas'><ConsultasExternas identificadorDoPaciente={identificador} consultasExternasDoPaciente={gestaoDeCondicoesClinicas.consultasExternas} /></Painel>
            <Painel key='registrosDocumentais'><RegistrosDocumentaisDoPsicologo identificadorDoPaciente={identificador} registrosDocumentaisDoPsicologoDoPaciente={gestaoDeCondicoesClinicas.registrosDocumentaisDoPsicologo} /></Painel>
            <Painel key='questionarios'><Questionarios identificadorDoPaciente={identificador} idadeDoPaciente={cabecalho.identificacao.idade} questionarios={gestaoDeCondicoesClinicas.questionarios} /></Painel>
            <Painel key='exames'><Exames identificadorDoPaciente={identificador} historicoDeCampos={historicoDeCampos} /></Painel>
            <Painel key='timeline'><TimelineDoPaciente historico={cabecalho.timeline} /></Painel>
          </Paineis>
        </Abas>
      </fieldset>
    </div>
  )
}
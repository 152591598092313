import React, { memo, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { abrirTeleconsultaNoProntuario } from '../../../../acoes/prontuario/teleconsulta'
import './style.css'

function VideoConsulta({
  identificadorDoAtendimento,
  ehRemotoPorVideoENaoTemTeleconsulta = false,
  recuperarTeleconsultaDoAtendimento,
  linksDaTeleconsulta,
}) {

  const dispatch = useDispatch()
  const [descricao, setDescricao] = useState(
    linksDaTeleconsulta
      ? 'Iniciar Vídeo Consulta'
      : 'Carregando Vídeo Consulta...'
  )

  useEffect(() => {
    if (linksDaTeleconsulta) {
      setDescricao('Iniciar Vídeo Consulta')
    }
  }, [linksDaTeleconsulta])

  useEffect(() => {
    if (identificadorDoAtendimento && !linksDaTeleconsulta) {
      const intervalos = [1000, 10000, 20000, 30000, 40000, 50000, 60000]
      const tempos = intervalos.map((intervalo) => {
        return setTimeout(() => {
          if (!linksDaTeleconsulta) {
            recuperarTeleconsultaDoAtendimento(identificadorDoAtendimento)
          }
        }, intervalo)
      })

      return () => {
        tempos.forEach(clearTimeout)
      }
    }
  }, [
    identificadorDoAtendimento,
    recuperarTeleconsultaDoAtendimento,
    linksDaTeleconsulta,
  ])

  function handleAbrir() {
    dispatch(abrirTeleconsultaNoProntuario(identificadorDoAtendimento))
    setDescricao('Reabrir a Vídeo Consulta')
  }

  return (
    <r-cell span={4} span-md='row' span-lg='row' class='text-align-center'>
      <div className='unity-infos__item teleconsulta'>
        <div>
          <strong>Links para a vídeo consulta:</strong> {'Paciente'}
          <button
            title='Copiar'
            className='botao-historico'
            type='button'
            disabled={ehRemotoPorVideoENaoTemTeleconsulta}
            onClick={() => {
              navigator.clipboard.writeText(linksDaTeleconsulta.paciente)
            }}
          >
            <i className='icon icon-copy' />
          </button>{' '}
          {'Convidado'}
          <button
            title='Copiar'
            className='botao-historico'
            type='button'
            disabled={ehRemotoPorVideoENaoTemTeleconsulta}
            onClick={() => {
              navigator.clipboard.writeText(linksDaTeleconsulta.convidado)
            }}
          >
            <i className='icon icon-copy' />
          </button>
        </div>
        <div className='mtp-5 '>
          <button
            type='button'
            className='button --primary w-sm-100 font-weight-normal'
            disabled={ehRemotoPorVideoENaoTemTeleconsulta}
            onClick={handleAbrir}
          >
            {descricao}
          </button>
        </div>
      </div>
    </r-cell>
  )
}

export default memo(VideoConsulta)
